const modifyDate = (date) => {
    let splitDate = date.split("-");
    let newDate = "";
    for(let i = splitDate.length - 1; i >= 0; i--) {
        newDate += splitDate[i];
        if (i !== 0) {
            newDate += "-";
        }
    }
    return newDate;
}
export default modifyDate;