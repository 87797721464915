import React, {
    useEffect,
    useContext,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import modifyDate from "../user_defined_script/modifyDate";
import Axios from "axios";
import searchData from "../user_defined_script/searchData";
import { UserContext } from "../App";
import LoadingAnimation from "../components/LoadingAnimation";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
function Tenders() {
    document.title = "CMD | TENDER";
    const preDefinedFunc = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [tenders, setTenders] = useState([]);
    const [allTenders, setAllTenders] = useState([]);
    const [tenderBanner, setTenderBanner] = useState([]);
    useEffect(() => {
        const tenderContainers = document.querySelectorAll(".tender-data-container");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        tenderContainers.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    const fetchTenders = async () => {
        let filterTender = [];
        let tenderBannerData = [];
        try {
            let allTenders = await Axios.get("https://admin.cmdncsm.in/tenders");
            let tenderBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Tenders");
            let tenders = allTenders.data;
            tenderBannerData = tenderBanner.data;
            filterTender = tenders
                .filter((data) => !data.archive_status)
                .map((data) => ({ ...data, addArr: [] }));

            for (let i = 0; i < filterTender.length; i++) {
                for (let j = 0; j < tenders.length; j++) {
                    if (filterTender[i].id === tenders[j].tender_mapping) {
                        filterTender[i].addArr.push(tenders[j]);
                    }
                }
            }
            return { filterTender, tenderBannerData };
        } catch(err) {
            return { error: true, filterTender, tenderBannerData };
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const setData = async () => {
            const data = await fetchTenders();
            if (data.error) {
                setTenders([]);
                setAllTenders([])
                setTenderBanner([]);
            } else {
                setTenders(data.filterTender);
                setAllTenders(data.filterTender)
                setTenderBanner(data.tenderBannerData);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        setData();
    }, []);

    const openPdf = (data) => {
        window.open(`https://admin.cmdncsm.in/public/tender_pdf/${data}`);
        return;
    }
    return (
        <div className="tender_page work-display position-relative display-margin-top-5rem ">
            {loading ? <LoadingAnimation /> : null}
            <div className="tender_page-header work-display-header">
                {
                    tenderBanner.length !== 0 ? tenderBanner[0].banner_title !== "" || tenderBanner[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${tenderBanner[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${tenderBanner[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {tenderBanner[0].banner_title}
                            </div>
                            {
                                tenderBanner[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{tenderBanner[0].banner_sub_title}</div> : null
                            }
                            {
                                tenderBanner[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">T{tenderBanner[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="searchContainer tender-padding">
                <div className="serachBox">
                    <input type="text" placeholder="Search:" id="serach" onChange={(e) => setTenders(searchData(e.target.value, allTenders.length !== 0 ? allTenders : []))} />
                    <div className="searchIcon">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>
            </div>
            <div className="tender-archive-infobar less-margin-for-mobile">
                {
                    tenders.length !== 0 ? tenders.map((item, index) => {
                        return (
                            <div className="tender-data-container" key={index}>
                                <div className="tender-data-left">
                                    <p className="count-tender-data-count">
                                        {index + 1 >= 10 ? index + 1 : `0${index + 1}`}
                                    </p>
                                    <div className="tender-submission-title">
                                        <p className="tender-submission-title-first">{item.tender_ref}</p>
                                        <p className="tender-submission-title-second">
                                            LAST DATE OF SUBMISSION: {modifyDate(item.last_date)}
                                        </p>
                                    </div>
                                </div>
                                <div className="tender-data-right">
                                    <div className="tender-data-msg-primary">
                                        <p className="letter-spacing-1px" dangerouslySetInnerHTML={{ __html: item.title }}></p>
                                    </div>
                                    <div className="tender-data-msg-secondary">
                                        <p className="letter-spacing-1px" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                    </div>
                                    <div className="responce-btn-box">
                                        <button className="responce-btn" type="button" onClick={() => openPdf(item.file)}>DOWNLOAD</button>
                                        {
                                            item.addArr.length !== 0 ? <Link className="tenderRelatableLink" to="/tenders-archive" state={{ relatableTender: item.addArr }}>{item.addArr.length > 1 ? "Relatable Archived Tenders" : "Relatable Archived Tender"}</Link> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <DataNotFoundMsg />
                }
            </div>
        </div>
    )
}

export default React.memo(Tenders);