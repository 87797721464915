import React, {
    useEffect,
    useState,
    useContext
} from "react";
import LoadingAnimation from "../components/LoadingAnimation";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
import modifyDate from "../user_defined_script/modifyDate";
import Axios from "axios";
import searchData from "../user_defined_script/searchData";
import { UserContext } from "../App";
function Notice() {
    document.title = "CMD | NOTICES"
    const [noticeBanner, setNoticeBanner] = useState([]);
    const [noticeMain, setNoticeMain] = useState([]);
    const [noticeOthers, setNoticeOthers] = useState([]);
    const [allNotices, setAllNotices] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchTenders = async () => {
        let noticeBannerData = [];
        let noticesMain = [];
        let noticesOthers = [];
        try {
            let noticeData = await Axios.get("https://admin.cmdncsm.in/notice-data");
            let noticeBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Notices");
            noticesMain = noticeData.data.filter((data) => {
                if (data.main) {
                    return data;
                } else return null;
            })
            noticesOthers = noticeData.data.filter((data) => {
                if (data.others) {
                    return data;
                } else return null;
            })
            noticeBannerData = noticeBanner.data;
            return { noticeBannerData, noticesMain, noticesOthers }
        } catch (error) {
            return { error: true, noticeBannerData, noticesMain, noticesOthers }
        }

    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const setData = async () => {
            let data = await fetchTenders();
            if (data.error) {
                setNoticeBanner([]);
                setNoticeMain([]);
                setAllNotices([]);
                setNoticeOthers([]);
            } else {
                setNoticeBanner(data.noticeBannerData);
                setNoticeMain(data.noticesMain);
                setAllNotices(data.noticesMain);
                setNoticeOthers(data.noticesOthers);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        setData();
    }, []);

    const preDefinedFunc = useContext(UserContext);
    useEffect(() => {
        const tenderContainers = document.querySelectorAll(".tender-data-container");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        tenderContainers.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });
    const openPdf = (file) => {
        window.open(`https://admin.cmdncsm.in/public/notice_data/${file}`);
        return;
    }
    return (
        <div className="notice_page work-display position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="notice_page-header work-display-header">
                {
                    noticeBanner.length !== 0 ? noticeBanner[0].banner_title !== "" || noticeBanner[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${noticeBanner[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${noticeBanner[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {noticeBanner[0].banner_title}
                            </div>
                            {
                                noticeBanner[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{noticeBanner[0].banner_sub_title}</div> : null
                            }
                            {
                                noticeBanner[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">T{noticeBanner[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="searchContainer tender-padding">
                <div className="serachBox">
                    <input type="text" placeholder="Search:" id="serach" onChange={(e) => setNoticeMain(searchData(e.target.value, allNotices.length !== 0 ? allNotices : []))} />
                    <div className="searchIcon">
                        <i className="fa-solid fa-magnifying-glass" ></i>
                    </div>
                </div>
            </div>
            <div className="tender-archive-infobar less-margin-for-mobile">
                {
                    noticeMain.length !== 0 ? noticeMain.map((data, index) => {
                        return (
                            <div className="tender-data-container notice-data-container" key={index}>
                                <div className="tender-data-left notice-data-left">
                                    {
                                        data.recent ? <img src="/media/logo_data/new_blink_logo.gif" alt="" /> : null
                                    }
                                    <div className="tender-data-msg-primary">
                                        <p className="letter-spacing-1px">{data.title}</p>
                                    </div>
                                    <div className="tender-submission-title">
                                        <p className="tender-submission-title-first">DATE OF PUBLICATION: {modifyDate(data.upload_date)}</p>
                                    </div>
                                    <div className="tender-data-msg-secondary">
                                        <p className="letter-spacing-1px" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                    </div>
                                    {
                                        (data.banner_image !== "File Not Selected!" && data.banner_image !== "File Not Selected" && data.banner_image !== "file not selected") ? <div className="notice-download-btn responce-btn-box">
                                            <button type="button" className="responce-btn" onClick={() => openPdf(data.banner_image)}>DOWNLOAD</button>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        )
                    }) : <DataNotFoundMsg />
                }
            </div>
            <div className="other-notice">
                <p>OTHERS NOTICES</p>
            </div>
            <div className="tender-archive-infobar less-margin-for-mobile-2rem">
                {
                    noticeOthers.length !== 0 ? noticeOthers.map((data, index) => {
                        return (
                            <div className="tender-data-container notice-data-container" key={index}>
                                <div className="tender-data-left notice-data-left">
                                    {
                                        data.recent ? <img src="/media/logo_data/new_blink_logo.gif" alt="" /> : null
                                    }
                                    <div className="tender-data-msg-primary">
                                        <p className="letter-spacing-1px">{data.title}</p>
                                    </div>
                                    <div className="tender-submission-title">
                                        <p className="tender-submission-title-first">DATE OF PUBLICATION: {modifyDate(data.upload_date)}</p>
                                    </div>
                                    <div className="tender-data-msg-secondary">
                                        <p className="letter-spacing-1px" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                    </div>
                                    {
                                        (data.banner_image !== "File Not Selected!" && data.banner_image !== "File Not Selected" && data.banner_image !== "file not selected") ? <div className="notice-download-btn responce-btn-box">
                                            <button type="button" className="responce-btn" onClick={() => openPdf(data.banner_image)}>DOWNLOAD</button>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        )
                    }) : <DataNotFoundMsg />
                }
            </div>
        </div>
    )
}

export default React.memo(Notice);