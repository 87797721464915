import React, {
    useEffect,
    useContext,
    useState
} from "react";
import Axios from "axios";
import {
    Link
} from "react-router-dom";
import sliceText from "../user_defined_script/sliceText";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
import LoadingAnimation from "../components/LoadingAnimation";
import { UserContext } from "../App";
import searchData from "../user_defined_script/searchData.js";
// import homeData from "../rawData/HomePageData";
function Product() {
    document.title = "CMD | PRODUCTS";
    const preDefinedFunc = useContext(UserContext);
    const [bannerData, setBannerData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const gridItem = document.querySelectorAll(".work-display-item");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        gridItem.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    const fetchData = async () => {
        let bannerData = [];
        let productData = [];
        try {
            let productBannerData = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Products");
            let products = await Axios.get("https://admin.cmdncsm.in/products");
            bannerData = productBannerData.data;
            productData = products.data;
            return { bannerData, productData }
        } catch (error) {
            return { error: true, bannerData, productData }
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const setData = async () => {
            let data = await fetchData();
            if (data.error) {
                setBannerData([]);
                setProductData([]);
                setAllProducts([]);
            } else {
                setBannerData(data.bannerData);
                setProductData(data.productData);
                setAllProducts(data.productData);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        setData();
    }, []);

    return (
        <div className="product_page work-display position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="product_page-header work-display-header">
                {
                    bannerData.length !== 0 ? bannerData[0].banner_title !== "" || bannerData[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {bannerData[0].banner_title}
                            </div>
                            {
                                bannerData[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{bannerData[0].banner_sub_title}</div> : null
                            }
                            {
                                bannerData[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">{bannerData[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="searchContainer">
                <div className="serachBox">
                    <input type="text" placeholder="Search:" onChange={(e) => { setProductData(searchData(e.target.value, allProducts.length !== 0 ? allProducts : [])) }} id="serach" />
                    <div className="searchIcon">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>
            </div>
            <div className="product_page-content work-display-content less-margin-for-mobile">
                <div className="home-recent-project-showcase work-display-item-grid">
                    {
                        productData.length !== 0 ? productData.map((data, index) => {
                            return (
                                <Link className="work-display-item" to="/show-product" key={index} state={{ index: index, totalData: productData }}>
                                    <div className="work-display-item_img">
                                        <picture>
                                            <source srcSet={`https://admin.cmdncsm.in/public/product_data/${data.banner_image1}`} media='(max-width: 500px)' />
                                            <img src={`https://admin.cmdncsm.in/public/product_data/${data.banner_image}`} alt="cmd_header_image" />
                                        </picture>
                                    </div>
                                    <div className="hoome-recent-project-item-text work-display-item-text">
                                        <h1>{data.title}</h1>
                                        <p>{sliceText(data.sort_description)}</p>
                                    </div>
                                </Link>
                            )
                        }) : <DataNotFoundMsg />
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(Product);