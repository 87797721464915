const sliceText = (text) => {
    let tempText = text.split(" ");
    let modifiedText = "";
    if (tempText.length < 20) {
        return text;
    } else {
        for(let i = 0; i < 20; i++) {
            modifiedText += tempText[i] += " "
        }
    }
    return modifiedText+="...";
}

export default sliceText;