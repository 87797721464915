import React, {
    useEffect,
    useContext,
    useState,
    useCallback,
} from "react";
import {
    Link
} from "react-router-dom";

import Axios from "axios";
import searchData from "../user_defined_script/searchData";
import { UserContext } from "../App";
import sliceText from "../user_defined_script/sliceText";
import LoadingAnimation from "../components/LoadingAnimation";
import DataNotFoundMsg from "../components/DataNotFoundMsg";

function Project() {
    document.title = "CMD | PROJECTS";
    const preDefinedFunc = useContext(UserContext);
    const [allProjects, setAllProjects] = useState([]);
    const [ongoingData, setOngoingData] = useState([]);
    const [completedProjects, setCompletedProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [searchProjects, setSearchProjects] = useState([]);
    const [loading, setLoading] = useState([]);

    const fetchData = async () => {
        let totalProjects = [];
        let ongoingProjects = [];
        let completedProjects = [];
        let projectBannerData = [];
        try {
            let projectBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Projects");
            let projectsData = await Axios.get("https://admin.cmdncsm.in/projects");
            let projectInsideData = await Axios.get("https://admin.cmdncsm.in/projects-inside-data");
            projectBannerData = projectBanner.data;

            totalProjects = projectsData.data.map((data) => {
                let addArr = [];
                return ({ ...data, addArr });
            })

            for (let i = 0; i < totalProjects.length; i++) {
                for (let j = 0; j < projectInsideData.data.length; j++) {
                    if (totalProjects[i].id === projectInsideData.data[j].image_key) {
                        totalProjects[i].addArr.push(projectInsideData.data[j]);
                    }
                }
            }

            ongoingProjects = totalProjects.filter((data) => {
                if (!data.comAndGoing) {
                    return data;
                } else return null;
            });

            completedProjects = totalProjects.filter((data) => {
                if (data.comAndGoing) {
                    return data;
                } else return null;
            })
            return { totalProjects, ongoingProjects, completedProjects, projectBannerData };
        } catch (error) {
            return { error: true, totalProjects, ongoingProjects, completedProjects, projectBannerData };
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const getData = async () => {
            let data = await fetchData();
            if (data.error) {
                setBannerData([]);
                setProjects([]);
                setAllProjects([]);
                setOngoingData([]);
                setCompletedProjects([]);
                setSearchProjects([]);
            } else {
                setBannerData(data.projectBannerData);
                setProjects(data.totalProjects);
                setAllProjects(data.totalProjects);
                setOngoingData(data.ongoingProjects);
                setCompletedProjects(data.completedProjects);
                setSearchProjects(data.totalProjects);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        getData();
    }, []);

    const observeNewDivFunc = (element, value, thresholdValue) => {
        const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
        observer.observe(element);
    }

    useEffect(() => {
        const gridItem = document.querySelectorAll(".work-display-item");
        const appearButton = document.querySelector(".ongoingCompleted_container");
        gridItem.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
        observeNewDivFunc(appearButton, "textTransformAnimation", .1);
    });

    const navigateCards = useCallback((e, target) => {
        e.target.classList.add("focusBtn");
        let focusBtn = document.querySelectorAll(".ongoingCompleted");
        focusBtn.forEach((button) => {
            if (button !== e.target) {
                button.classList.remove("focusBtn");
            }
        })
        switch (target) {
            case "ONGOING":
                setProjects(ongoingData);
                setSearchProjects(ongoingData);
                break;
            case "COMPLETED":
                setProjects(completedProjects);
                setSearchProjects(completedProjects);
                break;
            default:
                setProjects(allProjects);
                setSearchProjects(allProjects);
                break;
        }
    }, [ongoingData, completedProjects, allProjects])
    return (
        <div className="project_page work-display position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="project_page-header work-display-header">
                {
                    bannerData.length !== 0 ? bannerData[0].banner_title !== "" || bannerData[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {bannerData[0].banner_title}
                            </div>
                            {
                                bannerData[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{bannerData[0].banner_sub_title}</div> : null
                            }
                            {
                                bannerData[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">{bannerData[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="ongoingCompleted_container less-margin-for-mobile">
                <div className="ongoingCompleted-buttons">
                    <button type="button" onClick={(e) => navigateCards(e, "ALL")} className="ongoingCompleted responce-btn focusBtn">ALL</button>
                    <button type="button" onClick={(e) => navigateCards(e, "ONGOING")} className="ongoingCompleted  responce-btn">ONGOING</button>
                    <button type="button" onClick={(e) => navigateCards(e, "COMPLETED")} className="ongoingCompleted  responce-btn">COMPLETED</button>
                </div>
            </div>
            <div className="searchContainer project-padding-4rem">
                <div className="serachBox">
                    <input type="text" placeholder="Search:" id="serach" onChange={(e)=>{setProjects(searchData(e.target.value, searchProjects.length !== 0 ? searchProjects : []))}}/>
                    <div className="searchIcon">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>
            </div>
            <div className="project_page-content work-display-content">
                <div className="project-item-grid work-display-item-grid">
                    {
                        projects.length !== 0 ? projects.map((data, index) => {
                            return (
                                <Link className="product-item work-display-item" to="/details" key={index} state={{ data: data, heading1: "PROJECT", heading2: "DETAILS", sourcePath: "Project_images" }}>
                                    <div className="product-item_img work-display-item_img">
                                        <picture>
                                            <source srcSet={`https://admin.cmdncsm.in/public/Project_images/${data.banner_image_primary1}`} media='(max-width: 500px)' />
                                            <img src={`https://admin.cmdncsm.in/public/Project_images/${data.banner_image_primary}`} alt="cmd_header_image" />
                                        </picture>
                                    </div>
                                    <div className="product-item-text work-display-item-text">
                                        <h1 className="letter-spacing-1px">{data.title}</h1>
                                        <p>{data.sort_description !== "" ? sliceText(data.sort_description) : ""}</p>
                                    </div>
                                </Link>
                            )
                        }) : <DataNotFoundMsg />
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(Project);