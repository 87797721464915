import React, {
    useEffect,
    useContext,
    useState
} from "react";
import Axios from "axios";
import {
    Link
} from "react-router-dom";
import searchData from "../user_defined_script/searchData";
import sliceText from "../user_defined_script/sliceText";
import { UserContext } from "../App";
import LoadingAnimation from "../components/LoadingAnimation";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
function Product() {
    document.title = "CMD | EVENTS";
    const preDefinedFunc = useContext(UserContext);
    const [events, setEvents] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [loading, setLoading] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    useEffect(() => {
        const gridItem = document.querySelectorAll(".work-display-item");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        gridItem.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    const fetchData = async () => {
        let bannerData = [];
        let totalEvents = [];
        try {
            let eventBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Events");
            let eventsData = await Axios.get("https://admin.cmdncsm.in/events-data");
            let eventInsideData = await Axios.get("https://admin.cmdncsm.in/events-inside-data");
            totalEvents = eventsData.data.map((data) => {
                let addArr = [];
                return ({ ...data, addArr });
            })

            for (let i = 0; i < totalEvents.length; i++) {
                for (let j = 0; j < eventInsideData.data.length; j++) {
                    if (totalEvents[i].id === eventInsideData.data[j].image_key) {
                        totalEvents[i].addArr.push(eventInsideData.data[j]);
                    }
                }
            }

            bannerData = eventBanner.data;

            return { bannerData, totalEvents };
        } catch (error) {
            return { error: true, bannerData, totalEvents };
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const getData = async () => {
            let data = await fetchData();
            if (data.error) {
                setBannerData([]);
                setAllEvents([]);
                setEvents([]);
            } else {
                setBannerData(data.bannerData);
                setAllEvents(data.totalEvents);
                setEvents(data.totalEvents);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        getData();
    }, []);
    return (
        <div className="event_page work-display position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="event_page-header work-display-header">
                {
                    bannerData.length !== 0 ? bannerData[0].banner_title !== "" || bannerData[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {bannerData[0].banner_title}
                            </div>
                            {
                                bannerData[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{bannerData[0].banner_sub_title}</div> : null
                            }
                            {
                                bannerData[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">{bannerData[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="searchContainer padding-6rem">
                <div className="serachBox">
                    <input type="text" placeholder="Search:" id="serach" onChange={(e) => setEvents(searchData(e.target.value, allEvents.length !== 0 ? allEvents : []))} />
                    <div className="searchIcon">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>
            </div>
            <div className="product_page-content work-display-content less-margin-for-mobile">
                <div className="product-item-grid work-display-item-grid">
                    {
                        events.length !== 0 ? events.map((data, index) => {
                            return (
                                <Link className="product-item work-display-item" to="/details" key={index} state={{ data: data, heading1: "EVENT", heading2: "DETAILS", sourcePath: "events_data" }}>
                                    <div className="product-item_img work-display-item_img">
                                        <picture>
                                            <source srcSet={`https://admin.cmdncsm.in/public/events_data/${data.banner_image_primary1}`} media='(max-width: 500px)' />
                                            <img src={`https://admin.cmdncsm.in/public/events_data/${data.banner_image_primary}`} alt="cmd_header_image" />
                                        </picture>
                                    </div>
                                    <div className="product-item-text work-display-item-text">
                                        <h1 className="letter-spacing-1px">{data.title}</h1>
                                        <p dangerouslySetInnerHTML={{ __html: sliceText(data.sort_description) }}></p>
                                    </div>
                                </Link>
                            )
                        }) : <DataNotFoundMsg />
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(Product);