import React, {
    useEffect,
    useContext,
    useState
} from "react";
import { UserContext } from "../App";
import Axios from "axios";
import LoadingAnimation from "../components/LoadingAnimation";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
function OurStakeholders() {
    document.title = "CMD | STAKEHOLDERS";
    const preDefinedFunc = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [aboutData, setAboutData] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [directorData, setDirectorData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [stakeholderImage, setStakeholderImage] = useState([]);
    const [departmentA, setDepartmentA] = useState([]);
    const [departmentB, setDepartmentB] = useState([]);
    const [employeeA, setEmployeeA] = useState([]);
    const [employeeB, setEmployeeB] = useState([]);

    const fetchData = async () => {
        let data1 = []
        let data2 = []
        let data3 = []
        let data4 = []
        let data5 = []
        let departmentA = []
        let departmentB = [];
        try {
            let aboutCmdBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Our Stakeholders");
            let aboutCmdData = await Axios.get("https://admin.cmdncsm.in/about-us?identifier=stakeholder_heading_text");
            let directorData = await Axios.get("https://admin.cmdncsm.in/client-and-directors?identifier=director_data");
            let clientData = await Axios.get("https://admin.cmdncsm.in/client-and-directors?identifier=our_clients");
            let stakeholderImage = await Axios.get("https://admin.cmdncsm.in/about-us?identifier=stakeholder_image");
            let departmentData = await Axios.get("https://admin.cmdncsm.in/all-departments");
            let employeeData = await Axios.get("https://admin.cmdncsm.in/all-employee");
            departmentA = departmentData.data.filter(data => data.grade_of_employee === "A").map(data => ({ ...data, addArr: [] }))
            departmentB = departmentData.data.filter(data => data.grade_of_employee === "B").map(data => ({ ...data, addArr: [] }))
            for (let i = 0; i < departmentA.length; i++) {
                for (let j = 0; j < employeeData.data.length; j++) {
                    if (departmentA[i].id === employeeData.data[j].primary_key) {
                        departmentA[i].addArr.push(employeeData.data[j]);
                    }
                }
            }
            for (let i = 0; i < departmentB.length; i++) {
                for (let j = 0; j < employeeData.data.length; j++) {
                    if (departmentB[i].id === employeeData.data[j].primary_key) {
                        departmentB[i].addArr.push(employeeData.data[j]);
                    }
                }
            }
            data1 = aboutCmdBanner.data;
            data2 = aboutCmdData.data;
            data3 = directorData.data;
            data4 = clientData.data;
            data5 = stakeholderImage.data;
            return { data1, data2, data3, data4, data5, departmentA, departmentB };
        } catch (error) {
            return { error: true, data1, data2, data3, data4, data5, departmentA, departmentB };
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const getData = async () => {
            let data = await fetchData();
            if (data.error) {
                setBannerData([]);
                setAboutData([]);
                setDirectorData([]);
                setClientData([]);
                setStakeholderImage([])
                setDepartmentA([]);
                setDepartmentB([]);
                setEmployeeA([]);
                setEmployeeB([]);
            } else {
                setBannerData(data.data1);
                setAboutData(data.data2);
                setDirectorData(data.data3);
                setClientData(data.data4);
                setStakeholderImage(data.data5)
                setDepartmentA(data.departmentA);
                setDepartmentB(data.departmentB);
                setEmployeeA(data.departmentA[0] !== undefined ? data.departmentA[0].addArr : []);
                setEmployeeB(data.departmentB[0] !== undefined ? data.departmentB[0].addArr : []);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        getData();
    }, []);

    useEffect(() => {
        const animationElements = document.querySelectorAll(".heading-style, .stakeholder-data, .cmd-team-image, .animation-text");
        const observeNewDivFunc = (element, value) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: .1 });
            observer.observe(element);
        }
        animationElements.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation");
        });
    })

    const clearClass = (className, setBtn) => {
        document.querySelectorAll(`.${className}`).forEach((btns) => {
            if (btns !== setBtn) {
                btns.classList.remove("focusButton");
            } else {
                btns.classList.add("focusButton");
            }
        })
    }

    const clearAndSetData = (argc, data, btn) => {
        if (argc === "button1") {
            clearClass(argc, btn.target);
            setEmployeeA(data);
        } else {
            clearClass(argc, btn.target);
            setEmployeeB(data);
        }
    }
    return (
        <div className="stakeholders_page work-display position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="stakeholders_page-header about_page-header work-display-header">
                {
                    bannerData.length !== 0 ? bannerData[0].banner_title !== "" || bannerData[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file}`} alt="cmd_header_image" className="stakeholders_header-img  work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {bannerData[0].banner_title}
                            </div>
                            {
                                bannerData[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{bannerData[0].banner_sub_title}</div> : null
                            }
                            {
                                bannerData[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">T{bannerData[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="stakeholder-data-container less-margin-for-mobile">
                {
                    aboutData[0] !== undefined ? <div className="stakeholder-main-heading show-description-heading our-cluent-margin-top">
                        <h1 className="heading-style">{aboutData[0].heading_primary}</h1>
                        <p className="animation-text">{aboutData[0].sub_text}</p>
                    </div> : null
                }
                <div className="stakeholder-all-data">
                    {
                        aboutData[0] !== undefined && directorData.length !== 0 ? <div className="stakeholder-officer-data stakeholder-data our-client-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{aboutData[0] !== undefined ? aboutData[0].heading_primary : null}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        directorData.length !== 0 ? directorData.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <p><b>{data.name}</b></p>
                                                        <p>{data.designation}</p>
                                                        <p>{data.address}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        }) : (<tr>
                                            <td>
                                                <h1 style={{ textAlign: "center" }}>Data Not Found!</h1>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : null
                    }
                    {
                        aboutData[1] !== undefined ? <div className="stakeholder-main-heading show-description-heading our-cluent-margin-top">
                            <h1 className="heading-style">{aboutData[1].heading_primary}</h1>
                            <p className="animation-text">{aboutData[1].sub_text}</p>
                        </div> : null
                    }
                    {
                        aboutData[2] !== undefined ? <div className="stakeholder-main-heading show-description-heading our-cluent-margin-top">
                            <h1 className="heading-style">{aboutData[2].heading_primary}</h1>
                            <p className="animation-text" style={{ marginBottom: "2rem" }}>{aboutData[2].sub_text}</p>
                        </div> : null
                    }
                    {
                        aboutData[2] !== undefined && clientData.length !== 0 ? <div className="stakeholder-officer-data stakeholder-data our-client-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{aboutData[2] !== undefined ? aboutData[2].heading_primary : null}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        clientData.length !== 0 ? clientData.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {data.client_name}
                                                    </td>
                                                </tr>
                                            )
                                        }) : (<tr>
                                            <td>
                                                <h1 style={{ textAlign: "center" }}>Data Not Found!</h1>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : null
                    }
                    {
                        aboutData[3] !== undefined ? <div className="stakeholder-main-heading show-description-heading our-cluent-margin-top">
                            <h1 className="heading-style">{aboutData[3].heading_primary}</h1>
                            <p className="animation-text">{aboutData[3].sub_text}</p>
                        </div> : null
                    }
                    {
                        departmentA.length !== 0 ? <div className="stakeholder-officer-container stakeholder-data-box">
                            <div className="stakeholder-officer-heading stakeholder-data-heading">
                                <h1 className="heading-style heading-font-size">LIST OF OFFICERS OF CMD, KOLKATA</h1>
                            </div>
                            <div className="stakeholder-officer-data stakeholder-data">
                                <ul className="stakeholder-data-left stakeholder-data-left1">
                                    {
                                        departmentA.length !== 0 ? departmentA.map((data, index) => {
                                            if (index === 0) {
                                                return (
                                                    <li key={index}><button type="button" className="focusButton button1" onClick={(e) => clearAndSetData("button1", data.addArr, e)}>{data.department_name}</button ></li>
                                                )
                                            } else {
                                                return (
                                                    <li key={index}><button type="button" className="button1" onClick={(e) => clearAndSetData("button1", data.addArr, e)}>{data.department_name}</button ></li>
                                                )
                                            }
                                        }) : null
                                    }
                                </ul>
                                <div className="stakeholder-data-right stakeholder-data-right1">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>NAME</th>
                                                <th>DESIGNATION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                employeeA.length !== 0 ? employeeA.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{data.employee_name}</td>
                                                            <td>{data.designation_name}</td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : null
                    }
                    {
                        departmentB.length !== 0 ? <div className="stakeholder-officer-container stakeholder-data-box">
                            <div className="stakeholder-staff-heading stakeholder-data-heading">
                                <h1 className="heading-style heading-font-size">LIST OF STAFF MEMBERS OF CMD, KOLKATA</h1>
                            </div>
                            <div className="stakeholder-officer-data stakeholder-data">
                                <ul className="stakeholder-data-left stakeholder-data-left1">
                                    {
                                        departmentB.length !== 0 ? departmentB.map((data, index) => {
                                            if (index === 0) {
                                                return (
                                                    <li key={index}><button type="button" className="focusButton button2" onClick={(e) => clearAndSetData("button2", data.addArr, e)}>{data.department_name}</button ></li>
                                                )
                                            } else {
                                                return (
                                                    <li key={index}><button type="button" className="button2" onClick={(e) => clearAndSetData("button2", data.addArr, e)}>{data.department_name}</button ></li>
                                                )
                                            }
                                        }) : null
                                    }
                                </ul>
                                <div className="stakeholder-data-right stakeholder-data-right1">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>NAME</th>
                                                <th>DESIGNATION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                employeeB.length !== 0 ? employeeB.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{data.employee_name}</td>
                                                            <td>{data.designation_name}</td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : null
                    }
                    {
                        stakeholderImage.length !== 0 ? <div className="cmd-team-image" style={stakeholderImage[0].stakeholder_image_desktop === "" && stakeholderImage[0].stakeholder_image_mobile === "" ? { display: "none" } : { display: "block" }}>
                            <picture>
                                <source srcSet={`https://admin.cmdncsm.in/public/stakeholder_data/${stakeholderImage[0].stakeholder_image_mobile}`} media='(max-width: 500px)' />
                                <img src={`https://admin.cmdncsm.in/public/stakeholder_data/${stakeholderImage[0].stakeholder_image_desktop}`} alt="stakeholder_image" />
                            </picture>
                        </div> : <DataNotFoundMsg />
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(OurStakeholders);