import React, {
    useEffect
} from "react";
import { Link } from "react-router-dom";

function Header() {
    const clearMakeFocus = (items, compareElement, clearClass, yesOrNo) => {
        items.forEach((item) => {
            if (yesOrNo) {
                if (item !== compareElement) {
                    item.classList.remove(clearClass)
                }
            } else {
                item.classList.remove(clearClass);
            }
        })
    }

    const navigate_navbar = (e) => {
        const main_container = document.querySelector(".main_container");
        const header = document.querySelector(".header");
        let focusButtonContainer = document.querySelectorAll(".nav-inner-list_container");
        e.target.style.opacity = 0;
        e.target.style.zIndex = 0;
        const navButton = document.querySelector(".navigativeBar");
        const displayNone = document.querySelector(".burgerMenu_container");
        if (e.target.className.split(" ")[1] === "fa-bars") {
            e.target.nextSibling.style.opacity = 1;
            e.target.nextSibling.style.zIndex = 1;
            navButton.classList.add("hamburger_animation");
            displayNone.style.display = "flex";
            main_container.style.overflowY = "hidden"
            const increaseHamburgerMenu = document.querySelector(".hamburger_animation");
            clearMakeFocus(focusButtonContainer, "", "makeFocus", 0);
            if (header.getBoundingClientRect().top === 0) {
                increaseHamburgerMenu.style.setProperty("height", "calc(100vh)");
            } else {
                increaseHamburgerMenu.style.setProperty("height", `calc(100vh - ${header.getBoundingClientRect().top}px)`);
            }
        } else {
            e.target.previousSibling.style.opacity = 1;
            e.target.previousSibling.style.zIndex = 1;
            navButton.classList.remove("hamburger_animation");
            displayNone.style.display = "none";
            main_container.style.overflowY = "auto"
            clearMakeFocus(focusButtonContainer, "", "makeFocus", 0);
        }
    }

    useEffect(() => {
        const main_container = document.querySelector(".main_container");
        let focusButtonContainer = document.querySelectorAll(".nav-inner-list_container");
        const othersLink = document.querySelectorAll(".nav_link");
        const allLinks = document.querySelectorAll(".nav_link, .inner_nav_link");
        const bar = document.querySelector(".fa-bars");
        const cross = document.querySelector(".fa-xmark");

        const makeFocustoInnerLink = (e) => {
            let parentElemet = e.target.closest(".nav-inner-list_container");
            parentElemet.classList.add("makeFocus");
            clearMakeFocus(focusButtonContainer, parentElemet, "makeFocus", 1);
        }

        focusButtonContainer.forEach((button) => {
            button.addEventListener("click", makeFocustoInnerLink);
        });

        const clearHamburger = ()=> {
            document.querySelector(".burgerMenu_container").classList.remove("hamburger_animation")
            main_container.style.overflowY="auto"
            cross.style.zIndex = 0;
            cross.style.opacity = 0;
            bar.style.zIndex = 1;
            bar.style.opacity = 1;
        }

        allLinks.forEach((link) => {
            link.addEventListener("click", clearHamburger);
        })

        return () => {
            focusButtonContainer.forEach((button)=> {
                button.removeEventListener("click", makeFocustoInnerLink);
            })
            othersLink.forEach((button)=> {
                button.removeEventListener("click", clearMakeFocus(focusButtonContainer, "", "makeFocus", 0));
            })
            allLinks.forEach((link) => {
                link.removeEventListener("click", clearHamburger);
            })
        }
    }, []);

    return (
        <header className="header">
            <nav className="navbar">
                <div className="nav_left">
                    <img src="/media/logo_data/cmd_logo1.png" alt="cmd-logo" />
                </div>
                <ul className="nav_right navigativeBar burgerMenu_container">
                    <li className="nav_link home removeFocus">
                        <Link to="/" className="inner_nav_link">
                            <span>HOME</span>
                        </Link>
                    </li>
                    <li className="nav_link product removeFocus">
                        <Link to="/products" className="inner_nav_link">
                            <span>PRODUCTS</span>
                        </Link>
                    </li>
                    <li className="nav-inner-list_container project removeFocus">
                        <div>
                            <span className="nav-inner-list_heading" >PROJECTS</span>
                            <span>
                                <i className="nav-inner-list_arrow fa-solid fa-chevron-down"></i>
                            </span>
                        </div>
                        <ul className="nav-inner-list">
                            <li className="removeFocus">
                                <Link to="/projects" className="inner_nav_link">
                                    <span>PROJECTS</span>
                                </Link>
                            </li>
                            <li className="removeFocus">
                                <Link to="/dpr" className="inner_nav_link">
                                    <span>DPR</span>
                                </Link>
                            </li>
                            <li className="removeFocus">
                                <Link to="/photo-gallery" className="inner_nav_link">
                                    <span>Photo Gallery</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-inner-list_container tender removeFocus">
                        <div>
                            <span className="nav-inner-list_heading" >TENDERS</span>
                            <span><i className="nav-inner-list_arrow fa-solid fa-chevron-down"></i></span>
                        </div>
                        <ul className="nav-inner-list">
                            <li className="removeFocus">
                                <Link to="/tenders" className="inner_nav_link">
                                    <span>TENDERS</span>
                                </Link>
                            </li>
                            <li className="removeFocus">
                                <Link to="/tenders-archive" className="inner_nav_link">
                                    <span>TENDERS ARCHIVE</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-inner-list_container career removeFocus">
                        <div>
                            <span className="nav-inner-list_heading" >CAREER</span>
                            <span><i className="nav-inner-list_arrow fa-solid fa-chevron-down"></i></span>
                        </div>
                        <ul className="nav-inner-list">
                            <li className="removeFocus">
                                <Link to="/career" className="inner_nav_link">
                                    <span>CAREER</span>
                                </Link>
                            </li>
                            <li className="removeFocus">
                                <Link to="/apply-for-job" className="inner_nav_link">
                                    <span>APPLY FOR A JOB</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-inner-list_container about-cmd removeFocus">
                        <div>
                            <span className="nav-inner-list_heading" >ABOUT US</span>
                            <span><i className="nav-inner-list_arrow fa-solid fa-chevron-down"></i></span>
                        </div>
                        <ul className="nav-inner-list">
                            <li className="removeFocus">
                                <Link to="/about-cmd" className="inner_nav_link">
                                    <span>ABOUT CMD</span>
                                </Link>
                            </li>
                            <li className="removeFocus">
                                <Link to="/md-desk" className="inner_nav_link">
                                    <span>FROM THE DESK OF THE MD</span>
                                </Link>
                            </li>
                            <li className="removeFocus">
                                <Link to="/our-expertise" className="inner_nav_link">
                                    <span>OUR EXPERTISE</span>
                                </Link>
                            </li>
                            <li className="removeFocus">
                                <Link to="/stakeholders" className="inner_nav_link">
                                    <span>STAKEHOLDERS</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav_link notice removeFocus">
                        <Link to="/notice" className="inner_nav_link notice-new">
                            <img src="" alt="" />
                            <span>NOTICES</span>
                        </Link>
                    </li>
                    <li className="nav_link events removeFocus">
                        <Link to="/events" className="inner_nav_link">
                            <span>EVENTS</span>
                        </Link>
                    </li>
                    <li className="nav_link recent-development removeFocus">
                        <Link to="/recent-developments" className="inner_nav_link">
                            <span>RECENTS DEVELOPMENTS</span>
                        </Link>
                    </li>
                    <li className="nav_link contect-us removeFocus">
                        <Link to="/contact-us" className="inner_nav_link">
                            <span>CONTACT US</span>
                        </Link>
                    </li>
                </ul>
                <div className="navigate-navbar">
                    <i className="fa-solid fa-bars" onClick={(e)=> navigate_navbar(e)}></i>
                    <i className="fa-solid fa-xmark" onClick={(e)=> navigate_navbar(e)}></i>
                </div>
            </nav>
        </header>
    )
}

export default React.memo(Header);