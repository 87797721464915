import React, {
    useEffect,
    useContext,
    useRef,
    // useState
} from "react";

import { UserContext } from "../App";
import { useLocation } from "react-router-dom";

class staticData {
    static count = 0;
    static getIndex(index) {
        this.count = index;
    }
}

function ShowProduct() {
    document.title = "CMD | PRODUCTS DEATAILS"
    let data = useLocation();

    const productIndex = data.state.index;
    staticData.getIndex(productIndex);
    let totalData = data.state.totalData;
    const leftBtn = useRef(null);
    const leftBtnSource = useRef(null);
    const leftBtnImg = useRef(null);
    const rightBtn = useRef(null);
    const rightBtnSource = useRef(null);
    const rightBtnImg = useRef(null);
    const preDefinedFunc = useContext(UserContext);
    const productHeaderImageSource = useRef(null);
    const productHeaderImage = useRef(null);
    const productSecondaryImageSource = useRef(null);
    const productSecondaryImage = useRef(null);
    const productTitle = useRef(null);
    const productDescription = useRef(null);
    const leftBtnTitle = useRef(null);
    const rightBtnTitle = useRef(null);

    const observeNewDivFunc = (element, value, thresholdValue) => {
        const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
        observer.observe(element);
    }

    const isUnderLying = (id) => {
        if (id > 0 && id < totalData.length - 1) {
            leftBtn.current.style.display = "flex";
            rightBtn.current.style.display = "flex";
        }
    }

    const changeMainContent = (banner_image, banner_image1, secondary_image, secondary_image1, title, description) => {
        let clearClass = document.querySelectorAll(".showProduct-heading, .showProduct-summary, .showProduct-secondary-image");
        clearClass.forEach((item) => {
            item.classList.remove("textTransformAnimation");
        });
        productHeaderImageSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${banner_image1}`);
        productSecondaryImageSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${secondary_image1}`);
        productHeaderImage.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${banner_image}`);
        productSecondaryImage.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${secondary_image}`);
        productTitle.current.innerHTML = title;
        productDescription.current.innerHTML = description;
        clearClass.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        })
    }

    const leftNavigate = () => {
        document.querySelector(".main_container").scrollTo(0, 0);
        if (staticData.count > 0) {
            staticData.getIndex(staticData.count - 1);
            rightBtn.current.style.display = "flex";
            leftBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].banner_image1 : ""}`);
            leftBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].banner_image : ""}`);
            leftBtnTitle.current.innerHTML = `${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].title.length <= 20 ? totalData[staticData.count - 1].title : totalData[staticData.count - 1].title.slice(0, 20) + "..." : ""}`;
            rightBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].banner_image1 : ""}`);
            rightBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].banner_image : ""}`);
            rightBtnTitle.current.innerHTML = `${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].title.length <= 20 ? totalData[staticData.count + 1].title : totalData[staticData.count + 1].title.slice(0, 20) + "..." : ""}`;
            changeMainContent(totalData[staticData.count].banner_image, totalData[staticData.count].banner_image1, totalData[staticData.count].secondary_image, totalData[staticData.count].secondary_image1, totalData[staticData.count].title, totalData[staticData.count].description);
        }
        if (staticData.count === 0) {
            leftBtn.current.style.display = "none";
            return;
        }
        isUnderLying(staticData.count);
    }

    const rightNavigate = () => {
        document.querySelector(".main_container").scrollTo(0, 0);
        if (staticData.count < totalData.length - 1) {
            staticData.getIndex(staticData.count + 1);
            leftBtn.current.style.display = "flex";
            rightBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].banner_image1 : ""}`);
            rightBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].banner_image : ""}`);
            rightBtnTitle.current.innerHTML = `${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].title.length <= 20 ? totalData[staticData.count + 1].title : totalData[staticData.count + 1].title.slice(0, 20) + "..." : ""}`;
            leftBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].banner_image1 : ""}`);
            leftBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].banner_image : ""}`);
            leftBtnTitle.current.innerHTML = `${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].title.length <= 20 ? totalData[staticData.count - 1].title : totalData[staticData.count - 1].title.slice(0, 20) + "..." : ""}`;
            changeMainContent(totalData[staticData.count].banner_image, totalData[staticData.count].banner_image1, totalData[staticData.count].secondary_image, totalData[staticData.count].secondary_image1, totalData[staticData.count].title, totalData[staticData.count].description);
        }
        if (staticData.count === totalData.length - 1) {
            rightBtn.current.style.display = "none";
            return;
        }
        isUnderLying(staticData.count);
    }

    useEffect(() => {
        const animationContainer = document.querySelectorAll(".show-description-heading, .show-description-summary, .showProduct-secondary-image");
        animationContainer.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    useEffect(() => {
        if (totalData.length > 1) {
            if (staticData.count === 0) {
                leftBtn.current.style.display = "none";
                rightBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1].banner_image1}`);
                rightBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1].banner_image}`);
                return;
            }
            if (staticData.count === totalData.length - 1) {
                rightBtn.current.style.display = "none";
                leftBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1].banner_image1}`);
                rightBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1].banner_image}`);
                return;
            }
            leftBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1].banner_image1}`);
            leftBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1].banner_image}`);
            rightBtnSource.current.setAttribute("srcSet", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1].banner_image1}`)
            rightBtnImg.current.setAttribute("src", `https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1].banner_image}`);
        } else {
            leftBtn.current.style.display = "none";
            rightBtn.current.style.display = "none";
        }
    }, [totalData]);

    return (
        <div className="showProduct_page work-display display-margin-top-5rem">
            <div className="showProduct_page-header work-display-header">
                <picture>
                    <source srcSet={`https://admin.cmdncsm.in/public/product_data/${totalData[productIndex].banner_image1 !== undefined ? totalData[productIndex].banner_image1 : ""}`} media='(max-width: 500px)' ref={productHeaderImageSource} />
                    <img className="showProduct_header-img work-display-header-img" src={`https://admin.cmdncsm.in/public/product_data/${totalData[productIndex].banner_image !== undefined ? totalData[productIndex].banner_image : ''}`} alt="cmd_header_image" ref={productHeaderImage} />
                </picture>
                <div className="gradient gradient-more"></div>
                <div className="showProduct_header-text work-display-header-text">
                    <div className="showProduct_header-heading work-display_header-heading letter-spacing-2px">
                        PRODUCT
                    </div>
                    <div className="showProduct_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px" ref={productTitle}>
                        {totalData[staticData.count].title !== undefined ? totalData[staticData.count].title : ""}
                    </div>
                </div>
                <div className="productNavigateBtn left-btn" onClick={leftNavigate} ref={leftBtn}>
                    <div className='nav-btn-box1'>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].banner_image1 : ""}`} media='(max-width: 500px)' ref={leftBtnSource} />
                            <img className='left-btn-image' src={`https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].banner_image : ""}`} alt="cmd_header_image" ref={leftBtnImg} />
                        </picture>
                    </div>
                    <div className='nav-btn-box2'>
                        <i className="fa-solid fa-chevron-right btn-right"></i>
                    </div>
                    <span className='btn-title'>
                        <p className='productTitle' ref={leftBtnTitle}>{totalData[staticData.count - 1] !== undefined ? totalData[staticData.count - 1].title.length <= 20 ? totalData[staticData.count - 1].title : totalData[staticData.count - 1].title.slice(0, 20) + "..." : ""}</p>
                    </span>
                </div>
                <div className="productNavigateBtn right-btn" ref={rightBtn} onClick={rightNavigate} >
                    <div className='nav-btn-box2'>
                        <i className="fa-solid fa-chevron-left btn-right"></i>
                    </div>
                    <div className='nav-btn-box1 nav-btn-box1-right'>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].banner_image1 : ""}`} media='(max-width: 500px)' ref={rightBtnSource}/>
                            <img className='left-btn-image' src={`https://admin.cmdncsm.in/public/product_data/${totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].banner_image : ""}`} alt="cmd_header_image" ref={rightBtnImg} />
                        </picture>
                    </div>
                    <span className='btn-title'>
                        <p className='productTitle' ref={rightBtnTitle}>{totalData[staticData.count + 1] !== undefined ? totalData[staticData.count + 1].title.length <= 20 ? totalData[staticData.count + 1].title : totalData[staticData.count + 1].title.slice(0, 20) + "..." : ""}</p>
                    </span>
                </div>
            </div>
            <div className="showProduct-container show-description">
                <div className="showProduct-heading show-description-heading">
                    <h1 className="letter-spacing-1px">ABOUT THE PRODUCT</h1>
                </div>
                <div className="showProduct-summary show-description-summary">
                    <p className="text-justify" ref={productDescription} dangerouslySetInnerHTML={{ __html: totalData[staticData.count].description !== "" ? totalData[staticData.count].description : "" }}></p>
                </div>
            </div>
            <div className="showProduct-secondary-image">
                <picture>
                    <source srcSet={`https://admin.cmdncsm.in/public/product_data/${totalData[productIndex].secondary_image1 !== undefined ? totalData[productIndex].secondary_image1 : ""}`} media='(max-width: 500px)' ref={productSecondaryImageSource} />
                    <img src={`https://admin.cmdncsm.in/public/product_data/${totalData[productIndex].secondary_image !== undefined ? totalData[productIndex].secondary_image : ""}`} ref={productSecondaryImage} alt="cmd_header_image" />
                </picture>
            </div>
        </div>

    )
}

export default React.memo(ShowProduct);