import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    useRef
} from "react";
import {
    Link,
} from "react-router-dom";
import Axios from "axios";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
import LoadingAnimation from "../components/LoadingAnimation";
import { UserContext } from "../App";
function ApplyForJob() {
    document.title = "CMD | APPLY FOR JOB";
    const passwordHide = useRef(null);
    const passwordShow = useRef(null);
    const emailContainer = useRef(null);
    const verifyEmailButton = useRef(null);
    const preDefinedFunc = useContext(UserContext);
    const formCotainer = useRef(null);
    const submitBtn = useRef(null);
    const enterCodeContainer = useRef(null);
    const passwordHeading = useRef(null);
    const showPasswordContainer = useRef(null);
    const passwordContainer = useRef(null);
    const [loading, setLoading] = useState(true);
    const animation_container = useRef(null);
    const [applyForJobBanner, setApplyForJobBanner] = useState([]);
    const [information, setInformation] = useState([]);
    const [advertisementData, setAdvertisementData] = useState([]);
    const [guidelineData, setGuidelineData] = useState([]);
    const [postData, setPostData] = useState([]);
    const [info, setInfo] = useState([]);
    const [msg, setMsg] = useState(null);
    const [val, setVal] = useState({
        heading: "Login",
        hideName: "none",
        buttonName: "RESGISTER"
    });
    useEffect(() => {
        const animationContainer = document.querySelectorAll(".show-description-heading, .job-guideline-box");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        animationContainer.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    const checkMediaType = (mediaFormat) => {
        let extractFormat = mediaFormat.slice(mediaFormat.lastIndexOf("."));
        if (extractFormat === ".jpg" || extractFormat === ".JPG" || extractFormat === ".jpeg" || extractFormat === ".png" || extractFormat === ".PNG" || extractFormat === ".webg" || extractFormat === ".WEBG" || extractFormat === ".JPEG") {
            return true;
        } else {
            return false;
        }
    }

    const fetchDataLabels = async (url, id) => {
        let data = await Axios.get(`${url}?id=${id}`);
        return data.data;
    }

    const fetchData = useCallback(async () => {
        let data1 = [];
        let data2 = [];
        let data3 = [];
        let modifyingPostData = [];
        try {
            let applyForJobBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Apply For Job");
            let info = await Axios.get("https://admin.cmdncsm.in/career-data?table_name=career_data");
            let careerGuide = await Axios.get("https://career.cmdncsm.in/guideline-advertisemnt-data");
            let postData = await Axios.get("https://career.cmdncsm.in/show-new-post-data");
            modifyingPostData = postData.data.filter((data) => {
                if (data.visible)
                    return data;
                else
                    return false;
            });
            modifyingPostData = await Promise.all(modifyingPostData.map(async (data) => {
                let arr1 = [];
                let arr2 = [];

                let newData1 = await fetchDataLabels("https://career.cmdncsm.in/secondary-labels", data.id);
                let allData = ({ ...data, arr1, arr2 });

                newData1.forEach((data) => {
                    allData.arr1.push({ labels: data.labels, type: data.type });
                });

                let newData2 = await fetchDataLabels("https://career.cmdncsm.in/higher-secondary-labels", data.id);
                newData2.forEach((data) => {
                    allData.arr2.push({ labels: data.labels, type: data.type });
                });

                return allData;
            }))
            data1 = applyForJobBanner.data;
            data2 = info.data;
            data3 = careerGuide.data;
            return { data1, data2, data3, modifyingPostData };
        } catch (error) {
            return { error: true, data1, data2, data3, modifyingPostData };
        }
    }, []);

    useEffect(() => {
        const passwordShowRef = passwordShow.current;
        const passwordHideRef = passwordHide.current;
        const showPassword = (e) => {
            e.target.style.display = 'none';
            passwordHide.current.style.display = 'block';
            e.target.parentElement.previousElementSibling.type = 'password';
        };

        const hidePassword = (e) => {
            e.target.style.display = 'none';
            passwordShow.current.style.display = 'block';
            e.target.parentElement.previousElementSibling.type = 'text';
        };

        passwordShowRef.addEventListener('click', showPassword);
        passwordHideRef.addEventListener('click', hidePassword);

        if (localStorage.getItem("isLoggedIn") !== null) {
            formCotainer.current.style.display = "none";
            document.querySelector(".navigate-form").style.display = "none";
            document.querySelector(".all-jobs").style.display = "flex";
        }
        document.querySelector(".job-guideline-btn").classList.add("focusBtn");
        document.querySelector(".main_container").style.overflowY = "hidden";
        animation_container.current.style.display = "none";
        formCotainer.current.style.display = "none";
        const setData = async () => {
            let data = await fetchData();
            if (data.error) {
                setApplyForJobBanner([]);
                setInfo([]);
                setAdvertisementData({
                    heading: "",
                    img: "",
                })
                setGuidelineData({
                    heading: "",
                    description: "",
                    img: "",
                });
                setInformation({
                    heading: "",
                    description: "",
                    img: "",
                });
                setPostData([]);
            } else {
                setApplyForJobBanner(data.data1);
                setInfo(data.data2);
                setAdvertisementData({
                    heading: data.data3[0] !== undefined ? data.data3[0].advertisement_heading : "",
                    img: data.data3[0] !== undefined ? data.data3[0].advertisement_image : "",
                })
                setGuidelineData({
                    heading: data.data3[0] !== undefined ? data.data3[0].guideline_heading : "",
                    description: data.data3[0] !== undefined ? data.data3[0].guideline_description : "",
                    img: data.data3[0] !== undefined ? data.data3[0].guideline_image : "",
                });
                setInformation({
                    heading: data.data3[0] !== undefined ? data.data3[0].guideline_heading : "",
                    description: data.data3[0] !== undefined ? data.data3[0].guideline_description : "",
                    img: data.data3[0] !== undefined ? data.data3[0].guideline_image : "",
                });
                setPostData(data.modifyingPostData);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        setData();
        return () => {
            passwordShowRef.removeEventListener('click', showPassword);
            passwordHideRef.removeEventListener('click', hidePassword);
        };
    }, [fetchData])

    const navigateCards = useCallback((e, target) => {
        e.target.classList.add("focusBtn");
        let focusBtn = document.querySelectorAll(".job-guideline-btn");
        focusBtn.forEach((button) => {
            if (button !== e.target) {
                button.classList.remove("focusBtn");
            }
        })
        switch (target) {
            case "GUIDELINES":
                setInformation(guidelineData);
                break;
            case "ADVERTISEMENT":
                setInformation(advertisementData);
                break;
            default:
                break;
        }
    }, [guidelineData, advertisementData]);

    const changeForm = useCallback((e, target) => {
        setMsg("")
        formCotainer.current.style.display = "flex";
        formCotainer.current.firstElementChild.reset();
        document.querySelector(".navigate-form").style.display = "none";
        switch (target) {
            case "LOGIN":
                // enterCodeContainer.current.style.backgroundColor = "rgb(129 129 129 / 7%)";
                setVal({
                    heading: "Login",
                    hideName: "none",
                    buttonName: "REGISTER",
                    requestQuery: "login",
                    placeholderText: "Password"
                })
                passwordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.07)";
                showPasswordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.07)";
                emailContainer.current.disabled = false;
                passwordContainer.current.disabled = false;
                submitBtn.current.disabled = false;
                if (enterCodeContainer.current !== null) {
                    enterCodeContainer.current.disabled = false;
                }
                break;
            case "REGISTER":
                // enterCodeContainer.current.style.backgroundColor = "rgb(129 129 129 / 7%)";
                setVal({
                    heading: "Register",
                    hideName: "block",
                    buttonName: "LOGIN",
                    requestQuery: "register",
                    placeholderText: "Password"
                })
                passwordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.07)";
                showPasswordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.07)";
                passwordContainer.current.disabled = false;
                emailContainer.current.disabled = false;
                submitBtn.current.disabled = false;
                if (enterCodeContainer.current !== null) {
                    enterCodeContainer.current.disabled = false;
                }
                break;
            case "Reset Password":
                setVal({
                    heading: "Reset Password",
                    hideName: "none",
                    buttonName: "LOGIN",
                    requestQuery: "reset-password",
                    placeholderText: "New Password"
                })
                passwordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.02)";
                showPasswordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.02)";
                passwordContainer.current.disabled = true;
                submitBtn.current.disabled = true;
                break;
            default:
                break;
        }
    }, [])

    const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validPassword = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,}$/;
    const validName = /^[A-Z][a-z]*([ ][A-Z][a-z]*)*$/;

    const submitForm = (e, query) => {
        e.preventDefault();
        animation_container.current.style.display = "flex";
        let formData = e.target;
        let user_name = formData.username !== undefined ? formData.username.value : "";
        let user_email = formData.useremail !== undefined ? formData.useremail.value : "";
        let user_password = formData.userpassword !== undefined ? formData.userpassword.value : "";
        let verificationCode = formData.emailcode !== undefined ? formData.emailcode.value : "";
        if (validEmail.test(user_email) && validPassword.test(user_password)) {
            if (query === "login") {
                Axios.post(`https://career.cmdncsm.in/login`, {
                    email: user_email,
                    password: user_password
                }).then((res) => {
                    if (res.data) {
                        localStorage.setItem("isLoggedIn", true);
                        setTimeout(() => {
                            animation_container.current.style.display = "none";
                            formCotainer.current.style.display = "none";
                            document.querySelector(".all-jobs").style.display = "flex";
                        }, 3000);
                    } else {
                        setTimeout(() => {
                            animation_container.current.style.display = "none";
                            setMsg("Invalid email or password!");
                        }, 3000);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            } else if (query === "register") {
                if (validName.test(user_name) && validPassword.test(user_password) && validEmail.test(user_email)) {
                    Axios.post(`https://career.cmdncsm.in/sign-up`, {
                        name: user_name,
                        email: user_email,
                        password: user_password
                    }).then((res) => {
                        if (res.data) {
                            setTimeout(() => {
                                animation_container.current.style.display = "none";
                                setMsg("Successfully Registered!");
                                setTimeout(() => {
                                    setMsg("");
                                    changeForm("", "LOGIN");
                                }, 2000);
                            }, 3000);
                        } else {
                            setTimeout(() => {
                                animation_container.current.style.display = "none";
                                setMsg("Email address is already in use!");
                            }, 3000);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                } else {
                    setTimeout(() => {
                        animation_container.current.style.display = "none";
                        setMsg("Invalid Name!");
                    }, 3000);
                }
            } else if (query === "reset-password") {
                if (validPassword.test(user_password)) {
                    Axios.post(`https://career.cmdncsm.in/reset-password`, {
                        verificationCode: verificationCode,
                        email: user_email,
                        password: user_password
                    }).then((res) => {
                        if (res.data.flag) {
                            setTimeout(() => {
                                animation_container.current.style.display = "none";
                                setMsg(res.data.message);
                                setTimeout(() => {
                                    setMsg("");
                                    changeForm("", "LOGIN");
                                }, 2000);
                            }, 3000);
                        } else {
                            setTimeout(() => {
                                animation_container.current.style.display = "none";
                                setMsg(res.data.message);
                            }, 3000);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                }
            }
        } else {
            setTimeout(() => {
                animation_container.current.style.display = "none";
                setMsg("Enter valid email or password!");
            }, 3000);
        }
    }

    const showPdf = (data) => {
        window.open(data);
        return;
    }

    const verifyEmail = async (e) => {
        const email = document.querySelector("#useremail").value;
        const codeContainer = document.querySelector("#emailcode");
        if (email !== "" && validEmail.test(email)) {
            animation_container.current.style.display = "flex";
            const responce = await Axios.get(`https://career.cmdncsm.in/verify-email-address?email=${email}`);
            setTimeout(() => {
                animation_container.current.style.display = "none";
                if (responce.data.flag) {
                    setMsg(responce.data.message);
                    setTimeout(() => {
                        setMsg("");
                    }, 4000);
                    codeContainer.disabled = false;
                    codeContainer.style.backgroundColor = "rgba(255, 255, 255, 0.07)";
                    verifyEmailButton.current.disabled = true;
                    emailContainer.current.disabled = true;
                    submitBtn.current.disabled = false;
                    passwordContainer.current.disabled = false;
                    passwordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.07)";
                    showPasswordContainer.current.style.backgroundColor = "rgba(255, 255, 255, 0.07)";
                } else if (responce.data.flag === 0) {
                    setMsg(responce.data.message);
                    setTimeout(() => {
                        setMsg("");
                    }, 4000);
                }

            }, 3000);
        }
    }

    return (
        <div className="applyForJob_page position-relative work-display display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="career_page-header work-display-header">
                {
                    applyForJobBanner.length !== 0 ? applyForJobBanner[0].banner_title !== "" || applyForJobBanner[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${applyForJobBanner[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${applyForJobBanner[0].image_file}`} alt="cmd_header_image" className="tender_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {applyForJobBanner[0].banner_title}
                            </div>
                            {
                                applyForJobBanner[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{applyForJobBanner[0].banner_sub_title}</div> : null
                            }
                            {
                                applyForJobBanner[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">T{applyForJobBanner[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="career-container show-description less-margin-for-mobile">
                {
                    info.length !== 0 ? <div className="career-heading show-description-heading">
                        {info[0].title !== "" ? <h1 className="letter-spacing-1px">{info[0].title}</h1> : null}
                        {info[0].description !== "" ? <p className="company-address" dangerouslySetInnerHTML={{ __html: info[0].description }}></p> : null}
                    </div> : null
                }
            </div>
            <div className="job-guideline-box">
                <div className="job-guideline-navigate-btn">
                    <button className="job-guideline-btn responce-btn makeFocus" onClick={(e) => navigateCards(e, "GUIDELINES")}>GUIDELINES</button>
                    <button className="job-guideline-btn responce-btn" onClick={(e) => navigateCards(e, "ADVERTISEMENT")}>ADVERTISEMENT</button>
                </div>
                <div className="job-guideline-navigate-btn-style"></div>
                <div className="job-data-container">
                    <div className="job-guideline-data job-data job-data-focus">
                        <div className="gd-heading">
                            <h2>{information.heading}</h2>
                        </div>
                        <div className="gd-description" dangerouslySetInnerHTML={{ __html: information.description !== undefined ? information.description : "" }}></div>
                        <div className="gd-image">
                            {
                                information.img !== undefined ? checkMediaType(information.img) === true ? (<img src={`https://career.cmdncsm.in/public/advertisement_data/${information.img}`} alt="guideline_image" />) : (<object title="iframe1" className="ad-gd-pdf" data={`https://career.cmdncsm.in/public/advertisement_data/${information.img}`} frameBorder="0"></object>) : null
                            }
                            {/* {
                                information.img !== undefined && !checkMediaType(information.img) && (
                                    <button className="showpdf" onClick={() => showPdf(`https://career.cmdncsm.in/public/advertisement_data/${information.img}`)} style={information.img !== "" ? {display:"inline-block"}:{display:"none"}}>click here</button>
                                )
                            } */}
                        </div>
                    </div>
                    <div className="job-advertisement-data job-data post-container all-jobs">
                        {
                            postData.length !== 0 ? postData.map((data, index) => {
                                return (
                                    <Link className="responce-btn" to="/jobs" state={{ data: data }} key={index}>Apply for {data.post_name}</Link>
                                )
                            }) : <h1 style={{ color: "white", fontSize: "3rem" }}>Jobs are not available!</h1>
                        }
                    </div>
                    <div className="job-advertisement-data job-data post-container navigate-form">
                        <button className="responce-btn" onClick={(e) => changeForm(e, "LOGIN")}>LOGIN</button>
                        <p style={{ color: "white", letterSpacing: "2px" }}>OR</p>
                        <button className="responce-btn" onClick={(e) => changeForm(e, "REGISTER")}>REGISTER</button>
                    </div>
                    <div className="job-advertisement-data job-data post-container form-container" ref={formCotainer}>
                        <form onSubmit={(e) => submitForm(e, val.requestQuery)} className="form register-form">
                            <div ref={animation_container} className="animation-div">
                                <div className="circle-animation"></div>
                            </div>
                            <h3>{val.heading !== undefined ? val.heading : ""}</h3>
                            {msg && <h1 className="warning-msg">{msg}</h1>}
                            {val.hideName !== "none" ? <>
                                <label htmlFor="username" >Full Name</label>
                                <input type="text" placeholder="Name" id="username" name="username" required />
                            </> : null
                            }
                            <label htmlFor="useremail">Email</label>
                            <input required type="email" placeholder="Email" id="useremail" name="useremail" ref={emailContainer} />
                            <div className="verify-email" style={val.heading !== "Login" && val.heading !== "Register" ? { display: "flex" } : { display: "none" }}>
                                <button ref={verifyEmailButton} type="button" onClick={(e) => verifyEmail(e)}>Verify Email</button>
                            </div>
                            {
                                val.heading !== "Login" && val.heading !== "Register" ? <>
                                    <label htmlFor="emailcode">Code</label>
                                    <input required type="text" placeholder="Enter Code" id="emailcode" name="emailcode" ref={enterCodeContainer} style={{ backgroundColor: "rgba(255, 255, 255, 0.02)" }} disabled />
                                </> : null
                            }
                            <label htmlFor="userpassword" ref={passwordHeading}>{val.placeholderText}</label>
                            <div className="form-box">
                                <input required type="password" placeholder={val.placeholderText} id="userpassword" name="userpassword" ref={passwordContainer} />
                                <div className="show-password" ref={showPasswordContainer}>
                                    <i className="fa-solid password-show fa-eye" ref={passwordShow}></i>
                                    <i className="fa-solid password-hide fa-eye-slash" ref={passwordHide}></i>
                                </div>
                            </div>
                            <button type="submit" className="submit-btn" ref={submitBtn}>Submit</button>
                            <div className="form-others-btn">
                                <button type="button" onClick={(e) => changeForm(e, val.buttonName)}>{val.buttonName !== undefined ? val.buttonName : ""}</button>
                                <button type="button" style={val.heading === "Login" ? { display: "inline-block" } : { display: "none" }} onClick={(e) => changeForm(e, "Reset Password")} >Forget Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ApplyForJob);