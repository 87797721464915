import React, {
    useEffect,
    useContext,
    useState,
} from "react";
import Axios from 'axios';
import {
    Link
} from "react-router-dom";
import { UserContext } from "../App";
import LoadingAnimation from "../components/LoadingAnimation.js";
import DataNotFound from "../components/DataNotFoundMsg.js";
import sliceText from "../user_defined_script/sliceText.js";

function Home() {
    document.title = "CMD | HOME"
    const preDefinedFunc = useContext(UserContext);
    const [productsData, setProductsData] = useState([]);
    const [homeBannerData, setHomeBannerData] = useState([]);
    const [recentProjectData, setRecentProjectData] = useState([]);
    const [recentNotices, setRecentNotices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const recentHomeProject = document.querySelectorAll(".home-about_cmd_container, .home-recent_projects-heading, .work-display-item");

        const observeNewDivFunc = (element, value) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: .1 });
            observer.observe(element);
        }

        recentHomeProject.forEach((element) => {
            observeNewDivFunc(element, "textTransformAnimation");
        });
    })

    const getAllFetchedData = async () => {
        let data1 = [];
        let data2 = [];
        let filterProject = [];
        let data5 = [];
        try {
            let products = await Axios.get("https://admin.cmdncsm.in/products");
            let homeBannerData = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Home");
            let projectData = await Axios.get("https://admin.cmdncsm.in/recent-projects?recent_project=1&visible=1");
            let projectInsideData = await Axios.get("https://admin.cmdncsm.in/recent-projects-inside-data?onAndOff=1");
            let liveNotices = await Axios.get("https://admin.cmdncsm.in/live-notices");
            data1 = products.data;
            data2 = homeBannerData.data;
            let data3 = projectData.data;
            let data4 = projectInsideData.data;
            data5 = liveNotices.data;
            filterProject = data3.map((data) => {
                let addArr = [];
                return ({ ...data, addArr });
            })
            for (let i = 0; i < filterProject.length; i++) {
                for (let j = 0; j < data4.length; j++) {
                    if (filterProject[i].id === data4[j].image_key) {
                        filterProject[i].addArr.push(data4[j]);
                    }
                }
            }
            return { data1, data2, filterProject, data5 };
        } catch (error) {
            return { error: true, data1, data2, filterProject, data5 };
        }

    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const setAllData = async () => {
            let data = await getAllFetchedData();
            if (data.error) {
                setProductsData([]);
                setHomeBannerData([]);
                setRecentProjectData([]);
                setRecentNotices([]);
            } else {
                setProductsData(data.data1);
                setHomeBannerData(data.data2);
                setRecentProjectData(data.filterProject);
                setRecentNotices(data.data5);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        setAllData()
    }, []);

    useEffect(() => {
        if (productsData.length > 0) {
            let nextBtn = document.querySelector('.next')
            let prevBtn = document.querySelector('.prev')
            let slider = document.querySelector('.slider')
            let sliderList = slider.querySelector('.slider .list')

            let thumbnail = document.querySelector('.slider .thumbnail')
            let thumbnailItems = thumbnail.querySelectorAll('.item');

            thumbnail.appendChild(thumbnailItems[0])
            function moveSlider(direction) {
                let sliderInnerData = updateContainer().sliderItems;
                let thumbnailInnerData = updateContainer().thumbnailItems;
                if (direction === 'next') {
                    let arr1 = thumbnailInnerData[0].getAttribute("class").split(" ");
                    for (let i = 0; i < sliderInnerData.length; i++) {
                        let arr2 = sliderInnerData[i].getAttribute("class").split(" ")
                        if (arr2[arr2.length - 1] === arr1[arr1.length - 1]) {
                            sliderList.prepend(sliderInnerData[i]);
                            thumbnail.appendChild(thumbnailInnerData[0]);
                            break;
                        }
                    }
                    slider.classList.add('next');
                } else {
                    let arr1 = thumbnailInnerData.length > 1 ? thumbnailInnerData[thumbnailInnerData.length - 2].getAttribute("class").split(" ") : thumbnailInnerData[thumbnailInnerData.length - 1].getAttribute("class").split(" ");
                    for (let i = 0; i < sliderInnerData.length; i++) {
                        let arr2 = sliderInnerData[i].getAttribute("class").split(" ");
                        if (arr2[arr2.length - 1] === arr1[arr1.length - 1]) {
                            sliderList.prepend(sliderInnerData[i]);
                            thumbnail.prepend(thumbnailInnerData[thumbnailInnerData.length - 1]);
                            break;
                        }
                    }
                    slider.classList.add('prev')
                }

                slider.addEventListener('animationend', function () {
                    if (direction === 'next') {
                        slider.classList.remove('next')
                    } else {
                        slider.classList.remove('prev')
                    }
                }, { once: true })
            }

            let autoRunSlider = setInterval(() => {
                moveSlider("next");
            }, 5000);

            nextBtn.onclick = function () {
                clearInterval(autoRunSlider);
                moveSlider('next')
            }

            prevBtn.onclick = function () {
                clearInterval(autoRunSlider);
                moveSlider('prev')
            }

            let updateContainer = () => {
                let sliderItems = sliderList.querySelectorAll('.item')
                let thumbnailItems = document.querySelectorAll('.thumbnail .item');
                return { sliderItems, thumbnailItems };
            }

            const makeAnimation = (item) => {
                clearInterval(autoRunSlider);
                let sliderInnerData = updateContainer().sliderItems;
                let thumbNailItemClassValue = item.getAttribute("class");
                let getThumbNailLastClassArr = thumbNailItemClassValue.split(" ");
                let tLastClassVal = getThumbNailLastClassArr[getThumbNailLastClassArr.length - 1];

                for (let i = 0; i < sliderInnerData.length; i++) {
                    let getSliderClass = sliderInnerData[i].getAttribute("class");
                    let makeArr = getSliderClass.split(" ");
                    let getSliderLastClass = makeArr[makeArr.length - 1];
                    if (getSliderLastClass === tLastClassVal) {
                        sliderList.prepend(sliderInnerData[i]);
                        thumbnail.appendChild(item);
                        slider.classList.add("next");
                        break;
                    }
                }
                slider.addEventListener("animationend", () => {
                    slider.classList.remove("next");
                }, { once: true });
            }

            thumbnailItems.forEach((item) => {
                item.addEventListener("click", () => {
                    makeAnimation(item);
                });
            });
            return () => {
                clearInterval(autoRunSlider);
                thumbnailItems.forEach((item) => {
                    item.removeEventListener("click", () => {
                        makeAnimation(item);
                    });
                });
            }
        }
    }, [productsData])

    const openPdf = (file) => {
        window.open(`https://admin.cmdncsm.in/public/notice_data/${file}`);
        return;
    }

    return (
        <div className="home_container position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            {
                recentNotices.length !== 0 ? <marquee behavior="scroll" direction="left" loop="" className="marquee-container">
                    <div className="notice-content">
                        {
                            recentNotices.map((noticeData, index) => {
                                return (
                                    <div className="live-notice-group" key={index}>
                                        <img src="/media/logo_data/new_blink_logo.gif" alt="Logo" />
                                        <button type="button" onClick={() => openPdf(noticeData.banner_image)} className="notice-live-button">{noticeData.title}</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </marquee> : null
            }
            <div className="slider">
                <div className="list">
                    {
                        productsData.length !== 0 ? productsData.map((data, index) => {
                            return (
                                <div className={`item item${index + 1}`} key={index}>
                                    <picture>
                                        <source srcSet={`https://admin.cmdncsm.in/public/product_data/${data.banner_image1}`} media='(max-width: 500px)' />
                                        <img src={`https://admin.cmdncsm.in/public/product_data/${data.banner_image}`} alt="cmd_header_image" />
                                    </picture>
                                    <div className="gradient"></div>
                                    <div className="content">
                                        <div className="title">WE DEVELOP</div>
                                        <div className="type">{data.title}</div>
                                        <div className="button">
                                            <Link to="/show-product" state={{ index: index, totalData: productsData }}>
                                                <p>SEE MORE</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) : <DataNotFound />
                    }
                </div>
                <div className="thumbnail">
                    {
                        productsData.length !== 0 ? productsData.map((data, index) => {
                            return (
                                <div className={`item item${index + 1}`} key={index}>
                                    <picture>
                                        <source srcSet={`https://admin.cmdncsm.in/public/product_data/${data.banner_image1}`} media='(max-width: 500px)' />
                                        <img src={`https://admin.cmdncsm.in/public/product_data/${data.banner_image}`} alt="cmd_header_image" />
                                    </picture>
                                </div>
                            )
                        }) : ""
                    }
                </div>
                <div className="nextPrevArrows">
                    <button className="prev">
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                    <button className="next">
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>
            </div>
            <div className="home-about_cmd_container" style={homeBannerData.length !== 0 ? homeBannerData[0].banner_title === "" && homeBannerData[0].image_file === "" ? { display: "none" } : { display: "block" } : null}>
                {
                    homeBannerData.length !== 0 ? <>
                        <div className="home-about_cmd-up cmd-page-secondary-heading" style={homeBannerData[0].banner_title === "" ? { display: "none" } : { display: "" }}>
                            <h1>{homeBannerData[0].banner_title}</h1>
                        </div>
                        <div className="home-about_cmd-down">
                            <div className="home-about_cmd-left" style={homeBannerData[0].banner_sub_title === "" ? { display: "none" } : { display: "" }}>
                                <p className="text-justify">{homeBannerData[0].banner_sub_title}</p>
                            </div>
                            <div className="home-about_cmd-right" style={homeBannerData[0].image_file !== "" ? { display: "block" } : { display: "none" }}>
                                <picture className="display-flex-center">
                                    <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${homeBannerData[0].image_file1}`} media='(max-width: 500px)' />
                                    <img src={`https://admin.cmdncsm.in/public/banners_data/${homeBannerData[0].image_file}`} alt="home_banner_photo" />
                                </picture>
                            </div>
                        </div>
                    </> : <DataNotFound />
                }
            </div>
            <div className="home-recent_projects">
                <div className="home-recent_projects-heading cmd-page-secondary-heading">
                    <h1>RECENT PROJECTS</h1>
                </div>
                <div className="home-recent-project-showcase work-display-item-grid position-reltive">
                    {
                        recentProjectData.length !== 0 ? recentProjectData.map((data, index) => {
                            return (
                                <Link className="work-display-item" to="/details" key={index} state={{ data: data, heading1: "PROJECT", heading2: "PROJECT DETAILS", sourcePath: "Project_images" }}>
                                    <div className="work-display-item_img">
                                        <picture>
                                            <source srcSet={`https://admin.cmdncsm.in/public/Project_images/${data.banner_image_primary1}`} media='(max-width: 500px)' />
                                            <img src={`https://admin.cmdncsm.in/public/Project_images/${data.banner_image_primary}`} alt="cmd_header_image" />
                                        </picture>
                                    </div>
                                    <div className="hoome-recent-project-item-text work-display-item-text">
                                        <h1>{data.title}</h1>
                                        <p>{sliceText(data.sort_description)}</p>
                                    </div>
                                </Link>
                            )
                        }) : <DataNotFound />
                    }
                </div>
            </div>
        </div>
    );
}

export default React.memo(Home);