import React from "react";
import { Link } from "react-router-dom";
function NotFound() {
    return (
        <div className="section">
            <h1 className="error">404</h1>
            <div className="page">Oops!!! Page not found!</div>
            <Link className="responce-btn" to="/">Back to home</Link>
        </div>
    )
}

export default React.memo(NotFound);