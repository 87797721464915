import React, {
    useEffect,
    useContext,
    useRef,
    useState
} from "react";

import { useLocation, Navigate } from "react-router-dom";

import { UserContext } from "../App";
import DataNotFoundMsg from "./DataNotFoundMsg";
// import homeData from "../rawData/HomePageData";
// import sliceText from "../user_defined_script/sliceText";
class getCount {
    static count = 0;
    static increament(index) {
        this.count = index;
    }
}

function Details() {
    document.title = "CMD | PROJECTS DETAILS"
    let data = useLocation();
    let imageSource = useRef(null);
    let videoSource = useRef(null);
    const [mediaPathDesktop, setMediaPathDesktop] = useState("");
    const [mediaPathMobile, setMediaPathMobile] = useState("");
    const preDefinedFunc = useContext(UserContext);
    useEffect(() => {
        const animationContainer = document.querySelectorAll(".show-description-heading, .show-description-summary, .showProduct-secondary-image, .work-display-item");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        animationContainer.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    const checkMediaType = (mediaFormat) => {
        let extractFormat = mediaFormat.slice(mediaFormat.lastIndexOf("."));
        if (extractFormat === ".jpg" || extractFormat === ".JPG" || extractFormat === ".jpeg" || extractFormat === ".png" || extractFormat === ".PNG" || extractFormat === ".webg" || extractFormat === ".WEBG" || extractFormat === ".JPEG") {
            return true;
        } else {
            return false;
        }
    }
    const setDataToElement = (order, data, data1) => {
        if (order) {
            setMediaPathDesktop(data);
            setMediaPathMobile(data1);
            imageSource.current.style.display = "block";
            videoSource.current.style.display = "none";
        } else {
            setMediaPathDesktop(data);
            setMediaPathMobile(data1);
            videoSource.current.style.display = "block";
            imageSource.current.style.display = "none";
        }
    }

    const showcaseImages = (imageData, index) => {
        const main_container = document.querySelector(".main_container");
        const displayContainer = document.querySelector(".display_image-container");
        if (document.querySelector(".cmd-brand-container") !== null) {
            displayContainer.style.top = main_container.scrollTop - document.querySelector(".cmd-brand-container").clientHeight + "px";
        } else {
            displayContainer.style.top = `${main_container.scrollTop}px`;
        }
        displayContainer.classList.add("triggerDisplay");
        main_container.style.overflowY = "hidden";
        if (checkMediaType(imageData.image_path1)) {
            setDataToElement(true, imageData.image_path, imageData.image_path1);
        } else {
            setDataToElement(false, imageData.image_path, imageData.image_path1);
        }
        getCount.increament(index);
    }

    const cancelShowDisplayImages = () => {
        const displayContainer = document.querySelector(".display_image-container");
        const mainContainer = document.querySelector(".main_container");
        displayContainer.classList.remove("triggerDisplay");
        displayContainer.style.top = 0;
        mainContainer.style.overflowY = "scroll";
    }

    const leftImageHandler = () => {
        if (0 <= (getCount.count - 1)) {
            if (checkMediaType(data.state.data.addArr[getCount.count - 1].image_path1)) {
                setDataToElement(true, data.state.data.addArr[getCount.count - 1].image_path, data.state.data.addArr[getCount.count - 1].image_path1)
            } else {
                setDataToElement(false, data.state.data.addArr[getCount.count - 1].image_path, data.state.data.addArr[getCount.count - 1].image_path1)
            }
            getCount.increament(getCount.count - 1);
        }
    }

    const rightImageHandler = () => {
        if ((getCount.count + 1) < data.state.data.addArr.length) {
            if (checkMediaType(data.state.data.addArr[getCount.count + 1].image_path1)) {
                setDataToElement(true, data.state.data.addArr[getCount.count + 1].image_path, data.state.data.addArr[getCount.count + 1].image_path1)
            } else {
                setDataToElement(false, data.state.data.addArr[getCount.count + 1].image_path, data.state.data.addArr[getCount.count + 1].image_path1)
            }
            getCount.increament(getCount.count + 1);
        }
    }
    return (
        data.state !== null ? <div className="showProduct_page work-display display-margin-top-5rem">
            <div className="showProduct_page-header work-display-header">
                <picture>
                    <source srcSet={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${data.state.data.banner_image_primary1}`} media='(max-width: 500px)' />
                    <img className="showProduct_header-img work-display-header-img" src={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${data.state.data.banner_image_primary}`} alt="cmd_header_image" />
                </picture>
                <div className="gradient gradient-more"></div>
                <div className="showProduct_header-text work-display-header-text">
                    <div className="showProduct_header-heading work-display_header-heading letter-spacing-2px">
                        {data.state.heading1}
                    </div>
                    <div className="showProduct_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">
                        {data.state.data.title}
                    </div>
                </div>
            </div>
            <div className="showProduct-container show-description">
                <div className="showProduct-heading show-description-heading">
                    <h1 className="letter-spacing-1px">{data.state.heading2}</h1>
                </div>
                {
                    data.state.heading1 !== undefined && data.state.heading1 !== "Photo Gallery" ? <div className="showProduct-summary showProject-summary show-description-summary details">
                        {data.state.data.title !== undefined ? data.state.data.title !== "" ? <p className="detail-heading">{data.state.data.title}</p> : <></> : <></>}
                        {data.state.data.location !== undefined ? data.state.data.location !== "" ? <p><strong>LOCATION:</strong> {data.state.data.location}</p> : <></> : <></>}
                        {data.state.data.value !== undefined ? data.state.data.value !== "" ? <p><strong>PROJECT VALUE:</strong> {data.state.data.value}</p> : <></> : <></>}
                        {data.state.data.client_name !== undefined ? data.state.data.client_name !== "" ? <p><strong>CLIENT’S NAME:</strong> {data.state.data.client_name}</p> : <></> : <></>}
                        {data.state.data.commencement !== undefined ? data.state.data.commencement !== "" ? <p><strong>DATE OF COMMENCEMENT:</strong> {data.state.data.commencement}</p> : <></> : <></>}
                        {data.state.data.completion !== undefined ? data.state.data.completion !== "" ? <p><strong>DATE OF COMPLETION:</strong> {data.state.data.completion}</p> : <></> : <></>}
                        {data.state.data.description !== undefined ? data.state.data.description !== "" ? <p className="desc" dangerouslySetInnerHTML={{ __html: data.state.data.description }}></p> : <></> : <></>}
                        {data.state.data.video_link !== undefined ? data.state.data.video_link !== "" ? <p className="makeFocus"><strong>YOUTUBE LINK:</strong> <a href={data.state.data.video_link} target="__blank">{data.state.data.video_link}</a></p> : <></> : <></>}
                    </div> : null
                }
            </div>
            <div className="project_page-content work-display-content">
                <div className="project-item-grid show-projects-item work-display-item-grid padding-less">
                    {
                        data.state.data.addArr.length !== 0 ? data.state.data.addArr.map((innerData, index) => {
                            return (
                                <div className="project-item work-display-item" key={index} onClick={() => showcaseImages(innerData, index)} >
                                    {checkMediaType(innerData.image_path1) ? (
                                        <picture>
                                            <source srcSet={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${innerData.image_path1}`} media='(max-width: 500px)' />
                                            <img src={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${innerData.image_path}`} alt="cmd_header_image" />
                                        </picture>
                                    ) : (
                                        <video controls>
                                            <source src={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${innerData.image_path1}`} media='(max-width: 500px)' type="video/mp4" />
                                            <source src={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${innerData.image_path}`} type="video/mp4" />
                                        </video>
                                    )}
                                    {/* <div className="inner-photos-style">
                                        <p>{sliceText(innerData.image_alt_text)}</p>
                                    </div> */}
                                </div>
                            )
                        }) : <DataNotFoundMsg />
                    }
                </div>
            </div>
            <div className="display_image-container">
                <div className="left-bar" onClick={leftImageHandler}>
                    <i className="fa-solid fa-chevron-left btn"></i>
                </div>
                <div className='imageScreen'>
                    <picture ref={imageSource} style={{ display: "none" }}>
                        <source className="display-image-source-for-img" media='(max-width: 500px)' srcSet={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${mediaPathMobile}`} />
                        <img className="display-image" alt="cmd_header_image" src={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${mediaPathDesktop}`} />
                    </picture>
                    <video ref={videoSource} controls style={{ display: "none" }}>
                        <source media='(max-width: 500px)' type="video/mp4" src={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${mediaPathMobile}`} />
                        <source type="video/mp4" src={`https://admin.cmdncsm.in/public/${data.state.sourcePath}/${mediaPathDesktop}`} />
                    </video>
                </div>
                <div className="right-bar" onClick={rightImageHandler}>
                    <i className="fa-solid fa-chevron-right btn" ></i>
                </div>
                <div className="close-bar btn" onClick={cancelShowDisplayImages}>
                    <span className='cancelBtn'></span>
                </div>
            </div>
        </div> : < Navigate to="*" />
    )
}

export default React.memo(Details);