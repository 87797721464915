import React from "react";

function DataNotFound() {
    return (
        <div className="data-not-found" style={{padding: "4rem"}}>
            <h1 style={{color: "white"}}>Data Not Found!</h1>
        </div>
    )
}

export default React.memo(DataNotFound);