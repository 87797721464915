import React, {
    useEffect,
    useContext,
    useState
} from "react";
import { UserContext } from "../App";
import Axios from "axios";
import LoadingAnimation from "../components/LoadingAnimation";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
function OurExpertise() {
    document.title = "CMD | Our Expertise";
    const preDefinedFunc = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [aboutData, setAboutData] = useState([]);
    const [bannerData, setBannerData] = useState([])
    useEffect(() => {
        const animationContainer = document.querySelectorAll(".show-description-heading, .about_us-summary");
        const observeNewDivFunc = (element, value, thresholdValue) => {
            const observer = new IntersectionObserver(preDefinedFunc[1](value), { ...preDefinedFunc[0], threshold: thresholdValue });
            observer.observe(element);
        }
        animationContainer.forEach((item) => {
            observeNewDivFunc(item, "textTransformAnimation", .1);
        });
    });

    const fetchData = async () => {
        let data1 = [];
        let data2 = [];
        try {
            let aboutCmdBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Our Expertise");
            let aboutCmdData = await Axios.get("https://admin.cmdncsm.in/about-us?identifier=expertise");
            data1 = aboutCmdBanner.data;
            data2 = aboutCmdData.data;
            return { data1, data2 };
        } catch (error) {
            return { error:true, data1, data2 };
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const getData = async () => {
            let data = await fetchData();
            if (data.error) {
                setBannerData([]);
                setAboutData([]);
            } else {
                setBannerData(data.data1);
                setAboutData(data.data2);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        getData();
    }, []);

    return (
        <div className="about_page work-display position-relative display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="about_page-header image-top work-display-header">
                {
                    bannerData.length !== 0 ? bannerData[0].banner_title !== "" || bannerData[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file}`} alt="cmd_header_image" className="about_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="tender_header-text work-display-header-text">
                            <div className="tender_header-heading work-display_header-heading letter-spacing-2px">
                                {bannerData[0].banner_title}
                            </div>
                            {
                                bannerData[0].banner_sub_title !== "" ? <div className="about_header-sub-heading1 work-display_header-sub-heading1 letter-spacing-1px">{bannerData[0].banner_sub_title}</div> : null
                            }
                            {
                                bannerData[0].alter_text !== "" ? <div className="stakeholders_header-sub-heading2 work-display_header-sub-heading2">T{bannerData[0].alter_text}</div> : null
                            }
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
            <div className="about_us-container show-description display-margin-top-5rem">
                {
                    aboutData.length !== 0 ? <div className="about_us-container">
                        {
                            aboutData[0].title !== "" ? <div className="about_us-heading show-description-heading">
                                <h1 className="letter-spacing-1px">{aboutData[0].title}</h1>
                            </div> : null
                        }
                        {
                            aboutData[0].description !== "" ? <div className="about_us-summary show-description-summary">
                                <p className="text-justify" dangerouslySetInnerHTML={{ __html: aboutData[0].description }}></p>
                            </div> : null
                        }
                    </div> : <DataNotFoundMsg />
                }
            </div>
        </div>
    )
}

export default React.memo(OurExpertise);