const searchData = (queryText, data) => {
    const newArr = [];
    if (queryText !== "") {
        for (let i = 0; i < data.length; i++) {
            const dataText = data[i].title.toLowerCase();
            if (dataText.includes(queryText.toLowerCase())) {
                newArr.push(data[i]);
            }
        }
        return newArr;
    } else {
        return data;
    }
}

export default searchData;