import React, { useEffect } from "react";

function LoadingPage() {
    return (
        <div className="loading-page">
            <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <text x="50%" y="50%" fill="orangered" fontSize="100">CMD</text>
            </svg>
        </div>
    )
}

export default React.memo(LoadingPage);