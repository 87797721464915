import React, {
    useRef,
    useState,
    useEffect
} from "react";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import DataNotFoundMsg from "../components/DataNotFoundMsg";
import LoadingAnimation from "../components/LoadingAnimation";
function ContactUs() {
    document.title = "CMD | CONTACT US";
    const key = "6LcxWuYpAAAAAPMM9ui-5LPUb9I4Vu_gen8G0AIZ";
    const [submitBtnDisAppear, setSubmitBtnDisAppear] = useState(false);
    const [links, setLinks] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const onChange = () => {
        setSubmitBtnDisAppear(true);
    }
    const form = useRef();
    const [showMessage, setShowMessage] = useState(null);
    const makeNull = () => {
        setTimeout(() => {
            setShowMessage(null);
            setSubmitBtnDisAppear(false);
        }, 2000)
    }
    const subminForm = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const address = e.target.address.value;
        const phone_number = e.target.number.value;
        const email = `${e.target.email.value}`;
        const profession = e.target.profession.value;
        const message = e.target.message.value;
        setShowMessage(<div className="animation-buffer1"><span></span></div>);
        Axios.post("https://admin.cmdncsm.in/contact-us-data", {
            name, address, phone_number, email, profession, message
        }).then(() => {
            setShowMessage(
                <div className="animation-buffer3">
                    <p>Sucessful</p>
                </div>
            )
            form.current.reset();
            makeNull();
        }).catch(() => {
            setShowMessage(
                <div className="animation-buffer2">
                    <p>Unsuccessful</p>
                </div>
            )
            makeNull();
        })
    }
    const fetchData = async () => {
        let linkData = [];
        let bannerData = [];
        try {
            let contactBanner = await Axios.get("https://admin.cmdncsm.in/banner-data?identifier=Contact Us");
            let links = await Axios.get("https://admin.cmdncsm.in/links");
            linkData = links.data;
            bannerData = contactBanner.data;
            return { bannerData, linkData };
        } catch (error) {
            return { error: true, bannerData, linkData };
        }
    }

    useEffect(() => {
        document.querySelector(".main_container").style.overflowY = "hidden";
        const getData = async () => {
            let data = await fetchData();
            if (data.error) {
                setBannerData([]);
                setLinks([]);
            } else {
                setBannerData(data.bannerData);
                setLinks(data.linkData);
            }
            setLoading(false);
            document.querySelector(".main_container").style.overflowY = "auto";
        }
        getData();
    }, []);
    return (
        <div className="contactUs_page position-relative work-display display-margin-top-5rem">
            {loading ? <LoadingAnimation /> : null}
            <div className="contactUs_page-header">
                {
                    bannerData.length !== 0 ? bannerData[0].banner_title !== "" || bannerData[0].image_file !== "" ? <>
                        <picture>
                            <source srcSet={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file1}`} media='(max-width: 500px)' />
                            <img src={`https://admin.cmdncsm.in/public/banners_data/${bannerData[0].image_file}`} alt="cmd_header_image" className="contactUs_header-img work-display-header-img" />
                        </picture>
                        <div className="gradient gradient-more"></div>
                        <div className="contact_content">
                            <div className="contactUs_header-text">
                                {
                                    bannerData[0].banner_title !== "" ? <div className="contactUs_header-heading letter-spacing-2px">
                                        {bannerData[0].banner_title}
                                    </div> : null
                                }
                                {
                                    bannerData[0].banner_sub_title !== "" ? <div className="contactUs_header-sub-heading1 letter-spacing-1px">
                                        {bannerData[0].banner_sub_title}
                                    </div> : null
                                }
                                {
                                    bannerData[0].alter_text !== "" ? <div className="contactUs_header-sub-heading2">
                                        {bannerData[0].alter_text}
                                    </div> : null
                                }
                                <div className="contactUs_header-sub-links">
                                    <a href={`tel:${links.length !== 0 ? links[0].link_heading2 : ""}`}>
                                        <i className="fa-solid fa-phone"></i>
                                    </a>
                                    <a href={links.length !== 0 ? links[1].link_heading2 : ""} target="_blank" rel="noreferrer">
                                        <i className="fa-solid fa-location-dot"></i>
                                    </a>
                                    <a href={`mailto:${links.length !== 0 ? links[2].link_heading2 : ""}`}>
                                        <i className="fa-solid fa-envelope"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="contact-form">
                                <div className='contact-form-container'>
                                    <form className='formBox' onSubmit={subminForm} ref={form}>
                                        <div className='infoBox'>
                                            <input type="text" placeholder='Your Name' name='name' className='inputFiend nameFiend'
                                                pattern="^[A-Z][a-z]*([ ][A-Z][a-z]*)*$"
                                                required />
                                        </div>
                                        <div className='infoBox'>
                                            <input type="text" placeholder='Your Address' name='address' className='inputFiend addressFiend' required />
                                        </div>
                                        <div className='infoBox'>
                                            <input type="text" placeholder='Your Phone Number +91  (optional)' pattern="^\+91\d{10}$" name='number' className='inputFiend numberFiend' />
                                        </div>
                                        <div className='infoBox'>
                                            <input type="email" placeholder='Your Email' name='email' className='inputFiend emailFiend' pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required />
                                        </div>
                                        <div className='infoBox'>
                                            <input type="text" placeholder='Your Profession' name='profession' className='inputFiend prefessionField' required />
                                        </div>
                                        <div className="infoBox">
                                            <textarea name="message" id="messageBox" cols="30" rows="4" placeholder='Drop Your Message' required ></textarea>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey={key}
                                            onChange={onChange}
                                        />
                                        {
                                            submitBtnDisAppear && <div className='submitCont'>
                                                <button type="submit" className='contact-submit-btn responce-btn'>Send Message</button>
                                                {showMessage}
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </> : <DataNotFoundMsg /> : null
                }
            </div>
        </div>
    )
}

export default React.memo(ContactUs);