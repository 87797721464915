import React, {
    useState,
    useEffect
} from "react";
import {
    Link
} from "react-router-dom";
import Axios from "axios";

function Footer() {
    const [links, setLinks] = useState([]);
    const fetchData = async () => {
        let data = [];
        try {
            let links = await Axios.get("https://admin.cmdncsm.in/links");
            data = links.data;
            return { data };
        } catch (error) {
            return { error: true, data };
        }
    }
    useEffect(() => {
        const getData = async () => {
            let data = await fetchData();
            if (data.error) {
                setLinks([]);
            } else {
                setLinks(data.data);
            }
        }
        getData();
    }, []);
    return (
        <footer className="footer" style={{ color: "white" }}>
            <div className="waves">
                <div className="wave" id="wave1"></div>
                <div className="wave" id="wave2"></div>
                <div className="wave" id="wave3"></div>
                <div className="wave" id="wave4"></div>
            </div>
            <div className="footer_content_container">
                <div className="footer_quicklinks">
                    <div className="quicklink-logo-container">
                        {
                            links.length !== 0 ? links[0].link_heading1 !== "" && links[0].link_heading2 !== "" ? <div className="quicklink-logo_box">
                                <a href={`tel:${links.length !== 0 ? links[0].link_heading2 : ""}`}>
                                    <div className="quicklink-logo">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            style={
                                                {
                                                    fill: "rgba(0, 0, 0, 1)", transform: "",
                                                    msFilter: ""
                                                }
                                            }>
                                            <path d="M20 10.999h2C22 5.869 18.127 2 12.99 2v2C17.052 4 20 6.943 20 10.999z"></path>
                                            <path d="M13 8c2.103 0 3 .897 3 3h2c0-3.225-1.775-5-5-5v2zm3.422 5.443a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a1 1 0 0 0-.086-1.391l-4.064-3.696z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="quicklink-logo-txt">
                                        <p>{links.length !== 0 ? links[0].link_heading1 : ""}</p>
                                    </div>
                                </a>
                            </div> : null : null
                        }
                        {
                            links.length !== 0 ? links[1].link_heading1 !== "" && links[1].link_heading2 !== "" ?
                                <div className="quicklink-logo_box quicklink-logo_box-margin-left">
                                    <a href={links.length !== 0 ? links[1].link_heading2 : ""} target="_blank" rel="noreferrer">
                                        <div className="quicklink-logo">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                style={
                                                    {
                                                        fill: "rgba(0, 0, 0, 1)", transform: "",
                                                        msFilter: ""
                                                    }
                                                }>
                                                <path d="M12 2C7.589 2 4 5.589 4 9.995 3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12 0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path>
                                            </svg>
                                        </div>
                                        <div className="quicklink-logo-txt">
                                            <p>{links.length !== 0 ? links[1].link_heading1 : ""}</p>
                                        </div>
                                    </a>
                                </div> : null :
                                null
                        }
                        {
                            links.length !== 0 ? links[2].link_heading1 !== "" && links[2].link_heading2 !== "" ?
                                <div className="quicklink-logo_box">
                                    <a href={`mailto:${links.length !== 0 ? links[2].link_heading2 : ""}`} target="_blank" rel="noreferrer">
                                        <div className="quicklink-logo">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                style={
                                                    {
                                                        fill: "rgba(0, 0, 0, 1)", transform: "",
                                                        msFilter: ""
                                                    }
                                                }>
                                                <path d="m18.73 5.41-1.28 1L12 10.46 6.55 6.37l-1.28-1A2 2 0 0 0 2 7.05v11.59A1.36 1.36 0 0 0 3.36 20h3.19v-7.72L12 16.37l5.45-4.09V20h3.19A1.36 1.36 0 0 0 22 18.64V7.05a2 2 0 0 0-3.27-1.64z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div className="quicklink-logo-txt">
                                            <p>{links.length !== 0 ? links[2].link_heading1 : ""}</p>
                                        </div>
                                    </a>
                                </div> : null :
                                null
                        }
                    </div>
                    <div className="downPart">
                        <div className="contactHeading quickLinkHeading">
                            <h3>QUICK LINKS</h3>
                        </div>
                        <div className='footer_quicklink'>
                            <ul className='firstRow'>
                                <li>
                                    <Link to="/">HOME</Link>
                                </li>
                                <li>
                                    <Link to="/products">PRODUCTS</Link>
                                </li>
                                <li>
                                    <Link to="/projects">PROJECTS</Link>
                                </li>
                                <li>
                                    <Link to="/dpr">DPR</Link>
                                </li>
                                <li>
                                    <Link to="/photo-gallery">PHOTO GALLERY</Link>
                                </li>
                                <li>
                                    <Link to="/tenders">TENDERS</Link>
                                </li>
                            </ul>
                            <ul className='secondRow'>
                                <li>
                                    <Link to="/tenders-archive">TENDERS ARCHIVE</Link>
                                </li>
                                <li>
                                    <Link to="/career">CAREER</Link>
                                </li>
                                <li>
                                    <Link to="/apply-for-job">APPLY FOR A JOB</Link>
                                </li>
                                <li>
                                    <Link to="/notice">NOTICES</Link>
                                </li>
                                <li>
                                    <Link to="/events">EVENTS</Link>
                                </li>
                                <li>
                                    <Link to="/recent-developments">RECENT DEVELOPMENTS</Link>
                                </li>
                            </ul>
                            <ul className='thirdRow'>
                                <li>
                                    <Link to="/about-CMD">ABOUT CMD</Link>
                                </li>
                                <li>
                                    <Link to="/md-desk">FROM MD'S DESK</Link>
                                </li>
                                <li>
                                    <Link to="/our-expertise">OUR EXPERTISE</Link>
                                </li>
                                <li>
                                    <Link to="/stakeholders">STAKEHOLDERS</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">CONTACT US</Link>
                                </li>
                            </ul>
                            <ul className='fourthRow'>
                                <li className='contact-area'>
                                    <strong>Contact</strong>
                                </li>
                                <li>
                                    <strong>Creative Museum Designers</strong>
                                </li>
                                <li>
                                    NCSM Campus, Building II, 6th Floor
                                    33, Block - GN, Sector - V,
                                </li>
                                <li>
                                    Bidhan Nagar, Kolkata - 700091, India
                                    Phone: 033 2357 6041
                                </li>
                                <li className="social-link-follow">
                                    <a target="_blank" rel="noreferrer" style={links.length !== 0 ? links[3].link_heading1 === "" && links[3].link_heading2 === "" ? { display: "none" } : { display: "inline-block" } : null} href={links.length !== 0 ? links[3].link_heading2 : null}>
                                        <i className="fa-brands fa-facebook"></i>
                                    </a>
                                    <a target="_blank" rel="noreferrer" style={links.length !== 0 ? links[4].link_heading1 === "" && links[4].link_heading2 === "" ? { display: "none" } : { display: "inline-block" } : null} href={links.length !== 0 ? links[4].link_heading2 : null}>
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>
                                    <a target="_blank" rel="noreferrer" style={links.length !== 0 ? links[5].link_heading1 === "" && links[5].link_heading2 === "" ? { display: "none" } : { display: "inline-block" } : null} href={links.length !== 0 ? links[5].link_heading2 : null}>
                                        <i className="fa-brands fa-twitter"></i>
                                    </a>
                                    <a target="_blank" rel="noreferrer" style={links.length !== 0 ? links[6].link_heading1 === "" && links[6].link_heading2 === "" ? { display: "none" } : { display: "inline-block" } : null} href={links.length !== 0 ? links[6].link_heading2 : null}>
                                        <i className="fa-brands fa-youtube"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer_company_provirder">
                    <p>© 2024 CMDNCSM.IN IS PROUDLY POWERED BY <a href="http://pritam.website" target="_blank" rel="noreferrer">pritam.website</a></p>
                </div>
            </div>
        </footer>
    );
}

export default React.memo(Footer);